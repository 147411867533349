body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.modal-content {
    padding: 0px !important;
}
.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    -webkit-animation: App-logo-spin infinite 20s linear;
            animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: column;
          flex-direction: column;
  -webkit-align-items: center;
          align-items: center;
  -webkit-justify-content: center;
          justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@-webkit-keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

@keyframes App-logo-spin {
  from {
    -webkit-transform: rotate(0deg);
            transform: rotate(0deg);
  }
  to {
    -webkit-transform: rotate(360deg);
            transform: rotate(360deg);
  }
}

.navWrapperStyle {
  -webkit-align-items: center;
          align-items: center;
  display: -webkit-flex;
  display: flex;
  -webkit-justify-content: center;
          justify-content: center;
}

.navLinkStyle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.9em;
  color: #FFF;
  margin-right: 15px;
  margin-left: 15px;
  font-weight: bold;
}

.navLinkStyle:hover{
  color: #00BCD4;
}

.secondaryNavLinkStyle {
  display: -webkit-flex;
  display: flex;
  -webkit-align-items: center;
          align-items: center;
  font-size: 0.8em;
  color: #333;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
}

.navBarIconStyle{
  margin-left:10px;
  margin-right:10px;
  font-size:1.5em;
}

.underCarouselSectionHeader {
  font-size:1.2em;
  font-weight: bold;
  color: #333;
}

.underCarouselSectionWrapper{
  border-left-width: 1px;
  border-left-color: #CCC;
  border-left-style: solid;
  padding-top:15px;
  padding-bottom:15px;
}

.underCarouselItemWrapper {
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction: row;
          flex-direction: row;
  -webkit-justify-content: center;
          justify-content: center;
}

.underCarouselIcon {
  font-size:2.2em;
  margin-right:20px;
  color: #03A9F4 !important;
}


/* --------------------------------------- */

.footer {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  /* padding: 50px; */
  margin-top: 50px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}


/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}


/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}

.footerpaymenticon{
  width:40px;
  height:auto;
  margin-right:10px;
  margin-top:5px;
}

/* DEMO GENERAL ============================== */
.hover {
  /* overflow: hidden; */
  width:100%;
  /* height:100%; */
  position: relative;
  padding-bottom: 100%;
  /* padding:10px; */
  /* margin:10px; */
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}


/* DEMO 1 ============================== */
.hover-1 img {
  /* width: 105%; */
  position: absolute;
  top: 0;
  left: -2%;
  transition: all 0.3s;
}

.hover-1-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
  padding-left:15px;
}

.hover-1 .hover-overlay {
  /* background: rgba(0, 0, 0, 0.1); */
}

.hover-1-description {
  -webkit-transform: translateY(0.5rem);
          transform: translateY(0.5rem);
  transition: all 0.4s;
  opacity: 0;
  color:#333;
}

.hover-1:hover .hover-1-content {
  bottom: 2rem;
}

.hover-1:hover .hover-1-description {
  opacity: 1;
  -webkit-transform: none;
          transform: none;
}

.hover-1:hover img {
  left: 0;
}

.hover-1:hover .hover-overlay {
  opacity: 0;
}


.relatedproductitem{ 
  width:200px;
  height:auto;
  margin: 0px 5px 0px 5px;
  /* background-color: #0099ff; */
  /* height:200px; */
  display: -webkit-flex;
  display: flex;
  -webkit-flex-direction:column;
          flex-direction:column;
  -webkit-justify-content: center;
          justify-content: center;
  -webkit-align-items: center;
          align-items: center;
}


.footerminimal {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
}
@font-face {
	font-family: 'header_icons';
	src: 'url("../fonts/header_icons.woff")';
	src: 'url("../fonts/header_icons.eot?#iefix")' format("embedded-opentype"), 'url("fonts/header_icons.woff")' format("woff"), 'url("../fonts/header_icons.ttf")' format("truetype"), 'url("../fonts/header_icons.svg#header_icons")' format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap
}

[class*='header-']:before {
	display: inline-block;
	font-family: 'header_icons';
	font-style: normal;
	font-weight: normal;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.header-icon_like_custom:before {
	content: '\0041'
}

.header-icon_cart_custom:before {
	content: '\0042'
}

.header-icon_access_custom:before {
	content: '\0043'
}

.header-icon_grid_custom:before {
	content: '\0044'
}

.header-icon_search_custom:before {
	content: '\0045'
}


/*!
 * Hamburgers
 * @description Tasty CSS-animated hamburgers
 * @author Jonathan Suh @jonsuh
 * @site https://jonsuh.com/hamburgers
 * @link https://github.com/jonsuh/hamburgers
 */

.hamburger {
	padding: 0;
	display: inline-block;
	cursor: pointer;
	transition-property: opacity, -webkit-filter;
	transition-property: opacity, filter;
	transition-property: opacity, filter, -webkit-filter;
	transition-duration: 0.15s;
	transition-timing-function: linear;
	font: inherit;
	color: inherit;
	text-transform: none;
	background-color: transparent;
	border: 0;
	margin: 0;
	overflow: visible
}

.hamburger-box {
	width: 26px;
	height: 26px;
	display: inline-block;
	position: relative
}

.hamburger-inner {
	display: block;
	top: 50%;
	margin-top: -3px
}

.hamburger-inner,
.hamburger-inner::before,
.hamburger-inner::after {
	width: 26px;
	height: 2px;
	background-color: #fff;
	border-radius: 0;
	position: absolute;
	transition-property: -webkit-transform;
	transition-property: transform;
	transition-property: transform, -webkit-transform;
	transition-duration: 0.15s;
	transition-timing-function: ease
}

.hamburger-inner::before,
.hamburger-inner::after {
	content: "";
	display: block
}

.hamburger-inner::before {
	top: -9px
}

.hamburger-inner::after {
	bottom: -9px
}

.hamburger--spin .hamburger-inner {
	transition-duration: 0.22s;
	transition-timing-function: cubic-bezier(0.55, 0.055, 0.675, 0.19)
}

.hamburger--spin .hamburger-inner::before {
	transition: top 0.1s 0.25s ease-in, opacity 0.1s ease-in
}

.hamburger--spin .hamburger-inner::after {
	transition: bottom 0.1s 0.25s ease-in, -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19);
	transition: bottom 0.1s 0.25s ease-in, transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19), -webkit-transform 0.22s cubic-bezier(0.55, 0.055, 0.675, 0.19)
}

.hamburger--spin.is-active .hamburger-inner {
	-webkit-transform: rotate(225deg);
	        transform: rotate(225deg);
	transition-delay: 0.12s;
	transition-timing-function: cubic-bezier(0.215, 0.61, 0.355, 1)
}

.hamburger--spin.is-active .hamburger-inner::before {
	top: 0;
	opacity: 0;
	transition: top 0.1s ease-out, opacity 0.1s 0.12s ease-out
}

.hamburger--spin.is-active .hamburger-inner::after {
	bottom: 0;
	-webkit-transform: rotate(-90deg);
	        transform: rotate(-90deg);
	transition: bottom 0.1s ease-out, -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1);
	transition: bottom 0.1s ease-out, transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1), -webkit-transform 0.22s 0.12s cubic-bezier(0.215, 0.61, 0.355, 1)
}

@font-face {
	font-family: 'themify';
	src: 'url("../fonts/themify.eot?-fvbane")';
	src: 'url("../fonts/themify.eot?#iefix-fvbane")' format("embedded-opentype"), 'url("../fonts/themify.woff?-fvbane")' format("woff"), 'url("../fonts/themify.ttf?-fvbane")' format("truetype"), 'url("../fonts/themify.svg?-fvbane#themify")' format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap
}

[class^="ti-"],
[class*=" ti-"] {
	font-family: 'themify';
	speak: none;
	font-style: normal;
	font-weight: normal;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.ti-wand:before {
	content: "\e600"
}

.ti-volume:before {
	content: "\e601"
}

.ti-user:before {
	content: "\e602"
}

.ti-unlock:before {
	content: "\e603"
}

.ti-unlink:before {
	content: "\e604"
}

.ti-trash:before {
	content: "\e605"
}

.ti-thought:before {
	content: "\e606"
}

.ti-target:before {
	content: "\e607"
}

.ti-tag:before {
	content: "\e608"
}

.ti-tablet:before {
	content: "\e609"
}

.ti-star:before {
	content: "\e60a"
}

.ti-spray:before {
	content: "\e60b"
}

.ti-signal:before {
	content: "\e60c"
}

.ti-shopping-cart:before {
	content: "\e60d"
}

.ti-shopping-cart-full:before {
	content: "\e60e"
}

.ti-settings:before {
	content: "\e60f"
}

.ti-search:before {
	content: "\e610"
}

.ti-zoom-in:before {
	content: "\e611"
}

.ti-zoom-out:before {
	content: "\e612"
}

.ti-cut:before {
	content: "\e613"
}

.ti-ruler:before {
	content: "\e614"
}

.ti-ruler-pencil:before {
	content: "\e615"
}

.ti-ruler-alt:before {
	content: "\e616"
}

.ti-bookmark:before {
	content: "\e617"
}

.ti-bookmark-alt:before {
	content: "\e618"
}

.ti-reload:before {
	content: "\e619"
}

.ti-plus:before {
	content: "\e61a"
}

.ti-pin:before {
	content: "\e61b"
}

.ti-pencil:before {
	content: "\e61c"
}

.ti-pencil-alt:before {
	content: "\e61d"
}

.ti-paint-roller:before {
	content: "\e61e"
}

.ti-paint-bucket:before {
	content: "\e61f"
}

.ti-na:before {
	content: "\e620"
}

.ti-mobile:before {
	content: "\e621"
}

.ti-minus:before {
	content: "\e622"
}

.ti-medall:before {
	content: "\e623"
}

.ti-medall-alt:before {
	content: "\e624"
}

.ti-marker:before {
	content: "\e625"
}

.ti-marker-alt:before {
	content: "\e626"
}

.ti-arrow-up:before {
	content: "\e627"
}

.ti-arrow-right:before {
	content: "\e628"
}

.ti-arrow-left:before {
	content: "\e629"
}

.ti-arrow-down:before {
	content: "\e62a"
}

.ti-lock:before {
	content: "\e62b"
}

.ti-location-arrow:before {
	content: "\e62c"
}

.ti-link:before {
	content: "\e62d"
}

.ti-layout:before {
	content: "\e62e"
}

.ti-layers:before {
	content: "\e62f"
}

.ti-layers-alt:before {
	content: "\e630"
}

.ti-key:before {
	content: "\e631"
}

.ti-import:before {
	content: "\e632"
}

.ti-image:before {
	content: "\e633"
}

.ti-heart:before {
	content: "\e634"
}

.ti-heart-broken:before {
	content: "\e635"
}

.ti-hand-stop:before {
	content: "\e636"
}

.ti-hand-open:before {
	content: "\e637"
}

.ti-hand-drag:before {
	content: "\e638"
}

.ti-folder:before {
	content: "\e639"
}

.ti-flag:before {
	content: "\e63a"
}

.ti-flag-alt:before {
	content: "\e63b"
}

.ti-flag-alt-2:before {
	content: "\e63c"
}

.ti-eye:before {
	content: "\e63d"
}

.ti-export:before {
	content: "\e63e"
}

.ti-exchange-vertical:before {
	content: "\e63f"
}

.ti-desktop:before {
	content: "\e640"
}

.ti-cup:before {
	content: "\e641"
}

.ti-crown:before {
	content: "\e642"
}

.ti-comments:before {
	content: "\e643"
}

.ti-comment:before {
	content: "\e644"
}

.ti-comment-alt:before {
	content: "\e645"
}

.ti-close:before {
	content: "\e646"
}

.ti-clip:before {
	content: "\e647"
}

.ti-angle-up:before {
	content: "\e648"
}

.ti-angle-right:before {
	content: "\e649"
}

.ti-angle-left:before {
	content: "\e64a"
}

.ti-angle-down:before {
	content: "\e64b"
}

.ti-check:before {
	content: "\e64c"
}

.ti-check-box:before {
	content: "\e64d"
}

.ti-camera:before {
	content: "\e64e"
}

.ti-announcement:before {
	content: "\e64f"
}

.ti-brush:before {
	content: "\e650"
}

.ti-briefcase:before {
	content: "\e651"
}

.ti-bolt:before {
	content: "\e652"
}

.ti-bolt-alt:before {
	content: "\e653"
}

.ti-blackboard:before {
	content: "\e654"
}

.ti-bag:before {
	content: "\e655"
}

.ti-move:before {
	content: "\e656"
}

.ti-arrows-vertical:before {
	content: "\e657"
}

.ti-arrows-horizontal:before {
	content: "\e658"
}

.ti-fullscreen:before {
	content: "\e659"
}

.ti-arrow-top-right:before {
	content: "\e65a"
}

.ti-arrow-top-left:before {
	content: "\e65b"
}

.ti-arrow-circle-up:before {
	content: "\e65c"
}

.ti-arrow-circle-right:before {
	content: "\e65d"
}

.ti-arrow-circle-left:before {
	content: "\e65e"
}

.ti-arrow-circle-down:before {
	content: "\e65f"
}

.ti-angle-double-up:before {
	content: "\e660"
}

.ti-angle-double-right:before {
	content: "\e661"
}

.ti-angle-double-left:before {
	content: "\e662"
}

.ti-angle-double-down:before {
	content: "\e663"
}

.ti-zip:before {
	content: "\e664"
}

.ti-world:before {
	content: "\e665"
}

.ti-wheelchair:before {
	content: "\e666"
}

.ti-view-list:before {
	content: "\e667"
}

.ti-view-list-alt:before {
	content: "\e668"
}

.ti-view-grid:before {
	content: "\e669"
}

.ti-uppercase:before {
	content: "\e66a"
}

.ti-upload:before {
	content: "\e66b"
}

.ti-underline:before {
	content: "\e66c"
}

.ti-truck:before {
	content: "\e66d"
}

.ti-timer:before {
	content: "\e66e"
}

.ti-ticket:before {
	content: "\e66f"
}

.ti-thumb-up:before {
	content: "\e670"
}

.ti-thumb-down:before {
	content: "\e671"
}

.ti-text:before {
	content: "\e672"
}

.ti-stats-up:before {
	content: "\e673"
}

.ti-stats-down:before {
	content: "\e674"
}

.ti-split-v:before {
	content: "\e675"
}

.ti-split-h:before {
	content: "\e676"
}

.ti-smallcap:before {
	content: "\e677"
}

.ti-shine:before {
	content: "\e678"
}

.ti-shift-right:before {
	content: "\e679"
}

.ti-shift-left:before {
	content: "\e67a"
}

.ti-shield:before {
	content: "\e67b"
}

.ti-notepad:before {
	content: "\e67c"
}

.ti-server:before {
	content: "\e67d"
}

.ti-quote-right:before {
	content: "\e67e"
}

.ti-quote-left:before {
	content: "\e67f"
}

.ti-pulse:before {
	content: "\e680"
}

.ti-printer:before {
	content: "\e681"
}

.ti-power-off:before {
	content: "\e682"
}

.ti-plug:before {
	content: "\e683"
}

.ti-pie-chart:before {
	content: "\e684"
}

.ti-paragraph:before {
	content: "\e685"
}

.ti-panel:before {
	content: "\e686"
}

.ti-package:before {
	content: "\e687"
}

.ti-music:before {
	content: "\e688"
}

.ti-music-alt:before {
	content: "\e689"
}

.ti-mouse:before {
	content: "\e68a"
}

.ti-mouse-alt:before {
	content: "\e68b"
}

.ti-money:before {
	content: "\e68c"
}

.ti-microphone:before {
	content: "\e68d"
}

.ti-menu:before {
	content: "\e68e"
}

.ti-menu-alt:before {
	content: "\e68f"
}

.ti-map:before {
	content: "\e690"
}

.ti-map-alt:before {
	content: "\e691"
}

.ti-loop:before {
	content: "\e692"
}

.ti-location-pin:before {
	content: "\e693"
}

.ti-list:before {
	content: "\e694"
}

.ti-light-bulb:before {
	content: "\e695"
}

.ti-Italic:before {
	content: "\e696"
}

.ti-info:before {
	content: "\e697"
}

.ti-infinite:before {
	content: "\e698"
}

.ti-id-badge:before {
	content: "\e699"
}

.ti-hummer:before {
	content: "\e69a"
}

.ti-home:before {
	content: "\e69b"
}

.ti-help:before {
	content: "\e69c"
}

.ti-headphone:before {
	content: "\e69d"
}

.ti-harddrives:before {
	content: "\e69e"
}

.ti-harddrive:before {
	content: "\e69f"
}

.ti-gift:before {
	content: "\e6a0"
}

.ti-game:before {
	content: "\e6a1"
}

.ti-filter:before {
	content: "\e6a2"
}

.ti-files:before {
	content: "\e6a3"
}

.ti-file:before {
	content: "\e6a4"
}

.ti-eraser:before {
	content: "\e6a5"
}

.ti-envelope:before {
	content: "\e6a6"
}

.ti-download:before {
	content: "\e6a7"
}

.ti-direction:before {
	content: "\e6a8"
}

.ti-direction-alt:before {
	content: "\e6a9"
}

.ti-dashboard:before {
	content: "\e6aa"
}

.ti-control-stop:before {
	content: "\e6ab"
}

.ti-control-shuffle:before {
	content: "\e6ac"
}

.ti-control-play:before {
	content: "\e6ad"
}

.ti-control-pause:before {
	content: "\e6ae"
}

.ti-control-forward:before {
	content: "\e6af"
}

.ti-control-backward:before {
	content: "\e6b0"
}

.ti-cloud:before {
	content: "\e6b1"
}

.ti-cloud-up:before {
	content: "\e6b2"
}

.ti-cloud-down:before {
	content: "\e6b3"
}

.ti-clipboard:before {
	content: "\e6b4"
}

.ti-car:before {
	content: "\e6b5"
}

.ti-calendar:before {
	content: "\e6b6"
}

.ti-book:before {
	content: "\e6b7"
}

.ti-bell:before {
	content: "\e6b8"
}

.ti-basketball:before {
	content: "\e6b9"
}

.ti-bar-chart:before {
	content: "\e6ba"
}

.ti-bar-chart-alt:before {
	content: "\e6bb"
}

.ti-back-right:before {
	content: "\e6bc"
}

.ti-back-left:before {
	content: "\e6bd"
}

.ti-arrows-corner:before {
	content: "\e6be"
}

.ti-archive:before {
	content: "\e6bf"
}

.ti-anchor:before {
	content: "\e6c0"
}

.ti-align-right:before {
	content: "\e6c1"
}

.ti-align-left:before {
	content: "\e6c2"
}

.ti-align-justify:before {
	content: "\e6c3"
}

.ti-align-center:before {
	content: "\e6c4"
}

.ti-alert:before {
	content: "\e6c5"
}

.ti-alarm-clock:before {
	content: "\e6c6"
}

.ti-agenda:before {
	content: "\e6c7"
}

.ti-write:before {
	content: "\e6c8"
}

.ti-window:before {
	content: "\e6c9"
}

.ti-widgetized:before {
	content: "\e6ca"
}

.ti-widget:before {
	content: "\e6cb"
}

.ti-widget-alt:before {
	content: "\e6cc"
}

.ti-wallet:before {
	content: "\e6cd"
}

.ti-video-clapper:before {
	content: "\e6ce"
}

.ti-video-camera:before {
	content: "\e6cf"
}

.ti-vector:before {
	content: "\e6d0"
}

.ti-themify-logo:before {
	content: "\e6d1"
}

.ti-themify-favicon:before {
	content: "\e6d2"
}

.ti-themify-favicon-alt:before {
	content: "\e6d3"
}

.ti-support:before {
	content: "\e6d4"
}

.ti-stamp:before {
	content: "\e6d5"
}

.ti-split-v-alt:before {
	content: "\e6d6"
}

.ti-slice:before {
	content: "\e6d7"
}

.ti-shortcode:before {
	content: "\e6d8"
}

.ti-shift-right-alt:before {
	content: "\e6d9"
}

.ti-shift-left-alt:before {
	content: "\e6da"
}

.ti-ruler-alt-2:before {
	content: "\e6db"
}

.ti-receipt:before {
	content: "\e6dc"
}

.ti-pin2:before {
	content: "\e6dd"
}

.ti-pin-alt:before {
	content: "\e6de"
}

.ti-pencil-alt2:before {
	content: "\e6df"
}

.ti-palette:before {
	content: "\e6e0"
}

.ti-more:before {
	content: "\e6e1"
}

.ti-more-alt:before {
	content: "\e6e2"
}

.ti-microphone-alt:before {
	content: "\e6e3"
}

.ti-magnet:before {
	content: "\e6e4"
}

.ti-line-double:before {
	content: "\e6e5"
}

.ti-line-dotted:before {
	content: "\e6e6"
}

.ti-line-dashed:before {
	content: "\e6e7"
}

.ti-layout-width-full:before {
	content: "\e6e8"
}

.ti-layout-width-default:before {
	content: "\e6e9"
}

.ti-layout-width-default-alt:before {
	content: "\e6ea"
}

.ti-layout-tab:before {
	content: "\e6eb"
}

.ti-layout-tab-window:before {
	content: "\e6ec"
}

.ti-layout-tab-v:before {
	content: "\e6ed"
}

.ti-layout-tab-min:before {
	content: "\e6ee"
}

.ti-layout-slider:before {
	content: "\e6ef"
}

.ti-layout-slider-alt:before {
	content: "\e6f0"
}

.ti-layout-sidebar-right:before {
	content: "\e6f1"
}

.ti-layout-sidebar-none:before {
	content: "\e6f2"
}

.ti-layout-sidebar-left:before {
	content: "\e6f3"
}

.ti-layout-placeholder:before {
	content: "\e6f4"
}

.ti-layout-menu:before {
	content: "\e6f5"
}

.ti-layout-menu-v:before {
	content: "\e6f6"
}

.ti-layout-menu-separated:before {
	content: "\e6f7"
}

.ti-layout-menu-full:before {
	content: "\e6f8"
}

.ti-layout-media-right-alt:before {
	content: "\e6f9"
}

.ti-layout-media-right:before {
	content: "\e6fa"
}

.ti-layout-media-overlay:before {
	content: "\e6fb"
}

.ti-layout-media-overlay-alt:before {
	content: "\e6fc"
}

.ti-layout-media-overlay-alt-2:before {
	content: "\e6fd"
}

.ti-layout-media-left-alt:before {
	content: "\e6fe"
}

.ti-layout-media-left:before {
	content: "\e6ff"
}

.ti-layout-media-center-alt:before {
	content: "\e700"
}

.ti-layout-media-center:before {
	content: "\e701"
}

.ti-layout-list-thumb:before {
	content: "\e702"
}

.ti-layout-list-thumb-alt:before {
	content: "\e703"
}

.ti-layout-list-post:before {
	content: "\e704"
}

.ti-layout-list-large-image:before {
	content: "\e705"
}

.ti-layout-line-solid:before {
	content: "\e706"
}

.ti-layout-grid4:before {
	content: "\e707"
}

.ti-layout-grid3:before {
	content: "\e708"
}

.ti-layout-grid2:before {
	content: "\e709"
}

.ti-layout-grid2-thumb:before {
	content: "\e70a"
}

.ti-layout-cta-right:before {
	content: "\e70b"
}

.ti-layout-cta-left:before {
	content: "\e70c"
}

.ti-layout-cta-center:before {
	content: "\e70d"
}

.ti-layout-cta-btn-right:before {
	content: "\e70e"
}

.ti-layout-cta-btn-left:before {
	content: "\e70f"
}

.ti-layout-column4:before {
	content: "\e710"
}

.ti-layout-column3:before {
	content: "\e711"
}

.ti-layout-column2:before {
	content: "\e712"
}

.ti-layout-accordion-separated:before {
	content: "\e713"
}

.ti-layout-accordion-merged:before {
	content: "\e714"
}

.ti-layout-accordion-list:before {
	content: "\e715"
}

.ti-ink-pen:before {
	content: "\e716"
}

.ti-info-alt:before {
	content: "\e717"
}

.ti-help-alt:before {
	content: "\e718"
}

.ti-headphone-alt:before {
	content: "\e719"
}

.ti-hand-point-up:before {
	content: "\e71a"
}

.ti-hand-point-right:before {
	content: "\e71b"
}

.ti-hand-point-left:before {
	content: "\e71c"
}

.ti-hand-point-down:before {
	content: "\e71d"
}

.ti-gallery:before {
	content: "\e71e"
}

.ti-face-smile:before {
	content: "\e71f"
}

.ti-face-sad:before {
	content: "\e720"
}

.ti-credit-card:before {
	content: "\e721"
}

.ti-control-skip-forward:before {
	content: "\e722"
}

.ti-control-skip-backward:before {
	content: "\e723"
}

.ti-control-record:before {
	content: "\e724"
}

.ti-control-eject:before {
	content: "\e725"
}

.ti-comments-smiley:before {
	content: "\e726"
}

.ti-brush-alt:before {
	content: "\e727"
}

.ti-youtube:before {
	content: "\e728"
}

.ti-vimeo:before {
	content: "\e729"
}

.ti-twitter:before {
	content: "\e72a"
}

.ti-time:before {
	content: "\e72b"
}

.ti-tumblr:before {
	content: "\e72c"
}

.ti-skype:before {
	content: "\e72d"
}

.ti-share:before {
	content: "\e72e"
}

.ti-share-alt:before {
	content: "\e72f"
}

.ti-rocket:before {
	content: "\e730"
}

.ti-pinterest:before {
	content: "\e731"
}

.ti-new-window:before {
	content: "\e732"
}

.ti-microsoft:before {
	content: "\e733"
}

.ti-list-ol:before {
	content: "\e734"
}

.ti-linkedin:before {
	content: "\e735"
}

.ti-layout-sidebar-2:before {
	content: "\e736"
}

.ti-layout-grid4-alt:before {
	content: "\e737"
}

.ti-layout-grid3-alt:before {
	content: "\e738"
}

.ti-layout-grid2-alt:before {
	content: "\e739"
}

.ti-layout-column4-alt:before {
	content: "\e73a"
}

.ti-layout-column3-alt:before {
	content: "\e73b"
}

.ti-layout-column2-alt:before {
	content: "\e73c"
}

.ti-instagram:before {
	content: "\e73d"
}

.ti-google:before {
	content: "\e73e"
}

.ti-github:before {
	content: "\e73f"
}

.ti-flickr:before {
	content: "\e740"
}

.ti-facebook:before {
	content: "\e741"
}

.ti-dropbox:before {
	content: "\e742"
}

.ti-dribbble:before {
	content: "\e743"
}

.ti-apple:before {
	content: "\e744"
}

.ti-android:before {
	content: "\e745"
}

.ti-save:before {
	content: "\e746"
}

.ti-save-alt:before {
	content: "\e747"
}

.ti-yahoo:before {
	content: "\e748"
}

.ti-wordpress:before {
	content: "\e749"
}

.ti-vimeo-alt:before {
	content: "\e74a"
}

.ti-twitter-alt:before {
	content: "\e74b"
}

.ti-tumblr-alt:before {
	content: "\e74c"
}

.ti-trello:before {
	content: "\e74d"
}

.ti-stack-overflow:before {
	content: "\e74e"
}

.ti-soundcloud:before {
	content: "\e74f"
}

.ti-sharethis:before {
	content: "\e750"
}

.ti-sharethis-alt:before {
	content: "\e751"
}

.ti-reddit:before {
	content: "\e752"
}

.ti-pinterest-alt:before {
	content: "\e753"
}

.ti-microsoft-alt:before {
	content: "\e754"
}

.ti-linux:before {
	content: "\e755"
}

.ti-jsfiddle:before {
	content: "\e756"
}

.ti-joomla:before {
	content: "\e757"
}

.ti-html5:before {
	content: "\e758"
}

.ti-flickr-alt:before {
	content: "\e759"
}

.ti-email:before {
	content: "\e75a"
}

.ti-drupal:before {
	content: "\e75b"
}

.ti-dropbox-alt:before {
	content: "\e75c"
}

.ti-css3:before {
	content: "\e75d"
}

.ti-rss:before {
	content: "\e75e"
}

.ti-rss-alt:before {
	content: "\e75f"
}

@font-face {
	font-family: 'fontello';
	src: 'url("../fonts/fontello.eot?33109840")';
	src: 'url("../fonts/fontello.eot?33109840#iefix")' format("embedded-opentype"), 'url("../fonts/fontello.woff2?33109840")' format("woff2"), 'url("../fonts/fontello.woff?33109840")' format("woff"), 'url("../fonts/fontello.ttf?33109840")' format("truetype"), 'url("../fonts/fontello.svg?33109840#fontello")' format("svg");
	font-weight: normal;
	font-style: normal;
	font-display: swap
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
	font-family: "fontello";
	font-style: normal;
	font-weight: normal;
	speak: none;
	display: inline-block;
	text-decoration: inherit;
	width: 1em;
	margin-right: .2em;
	text-align: center;
	-webkit-font-feature-settings: normal;
	        font-feature-settings: normal;
	font-variant: normal;
	text-transform: none;
	line-height: 1em;
	margin-left: .2em;
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

.icon-th:before {
	content: '\e800'
}

.icon-th-list:before {
	content: '\e801'
}

.icon-phone:before {
	content: '\e802'
}

.icon-mobile:before {
	content: '\e803'
}

.icon-mail:before {
	content: '\e804'
}

.icon-map:before {
	content: '\e805'
}

.icon-map-1:before {
	content: '\e806'
}

.icon-ok:before {
	content: '\e808'
}

.icon-ok-1:before {
	content: '\e809'
}

.icon-star:before {
	content: '\e80a'
}

.icon-star-empty:before {
	content: '\e80b'
}

.icon-star-half:before {
	content: '\e80c'
}

.icon-user:before {
	content: '\f061'
}

.icon-star-half-alt:before {
	content: '\f123'
}

.icon-sliders:before {
	content: '\f1de'
}

.icon-map-o:before {
	content: '\f278'
}

html * {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale
}

body {
	/* background: #f8f8f8; */
	font-size: 14px;
	font-size: 0.875rem;
	font-family: "Roboto", Arial, Helvetica, sans-serif;
	color: #444
}

h1,
h2,
h3,
h4,
h5,
h6 {
	color: #111
}

p {
	margin-bottom: 20px
}

strong {
	font-weight: 500
}

label {
	font-weight: 500;
	margin-bottom: 3px
}

hr {
	margin: 30px 0 30px 0;
	border: 0;
	border-top: 1px solid #ddd
}

.main_title {
	position: relative;
	margin-bottom: 45px;
	text-align: center
}

@media (max-width: 767px) {
	.main_title {
		margin-bottom: 30px
	}
}

.main_title h1,
.main_title h2 {
	font-size: 36px;
	font-size: 2.25rem;
	font-weight: 500;
	margin: 0;
	position: relative;
	z-index: 2
}

@media (max-width: 767px) {
	.main_title h1,
	.main_title h2 {
		font-size: 28px;
		font-size: 1.75rem
	}
}

.main_title h1 {
	font-size: 42px;
	font-size: 2.625rem
}

@media (max-width: 767px) {
	.main_title h1 {
		font-size: 32px;
		font-size: 2rem
	}
}

.main_title span {
	display: block;
	content: attr(data-mask);
	position: absolute;
	text-align: center;
	margin-top: -15px;
	top: 0;
	font-weight: 900;
	font-size: 65px;
	line-height: 1;
	z-index: 1;
	color: #f4f4f4;
	text-transform: uppercase;
	width: 100%
}

@media (max-width: 767px) {
	.main_title span {
		font-size: 45px;
		font-size: 2.8125rem
	}
}

.main_title p {
	font-size: 23px;
	font-size: 1.4375rem;
	font-weight: 300;
	padding: 0 15%;
	position: relative;
	z-index: 2
}

@media (max-width: 767px) {
	.main_title p {
		font-size: 18px;
		font-size: 1.125rem;
		padding: 0
	}
}

.page_header {
	margin-bottom: 30px
}

.page_header h1 {
	font-size: 24px;
	font-size: 1.5rem;
	margin: 0 0 5px 0
}

.breadcrumbs {
	line-height: 1;
	color: #555
}

.breadcrumbs ul {
	margin: 0 0 10px 0;
	padding: 0;
	font-size: 12px;
	font-size: 0.75rem
}

.breadcrumbs ul li {
	display: inline-block;
	margin-right: 7px;
	padding-right: 8px;
	position: relative
}

.breadcrumbs ul li a {
	color: #777
}

.breadcrumbs ul li a:hover {
	color: #004dda
}

.breadcrumbs ul li:after {
	font-family: 'themify';
	content: "\e649";
	font-size: 10px;
	font-size: 0.625rem;
	position: absolute;
	display: inline-block;
	top: 2px;
	margin-left: 3px
}

.breadcrumbs ul li:last-child {
	font-weight: 500
}

.breadcrumbs ul li:last-child:after {
	content: '';
	margin-right: 0
}

a {
	color: #004dda;
	text-decoration: none;
	transition: all 0.3s ease-in-out;
	outline: none
}

a:hover,
a:focus {
	color: #111;
	text-decoration: none;
	outline: none
}

a.btn_1,
.btn_1 {
	border: none;
	color: #fff;
	background: #004dda;
	outline: none;
	cursor: pointer;
	display: inline-block;
	text-decoration: none;
	padding: 12px 25px;
	color: #fff;
	font-weight: 500;
	text-align: center;
	font-size: 14px;
	font-size: 0.875rem;
	transition: all 0.3s ease-in-out;
	border-radius: 3px;
	line-height: normal
}

a.btn_1:focus,
.btn_1:focus {
	outline: none
}

a.btn_1:hover,
.btn_1:hover {
	background-color: #FFC107;
	color: #111 !important
}

a.btn_1.full-width,
.btn_1.full-width {
	display: block;
	width: 100%;
	text-align: center;
	margin-bottom: 5px
}

a.btn_1.medium,
.btn_1.medium {
	font-size: 16px;
	font-size: 1rem;
	padding: 18px 40px
}

a.btn_1.outline,
.btn_1.outline {
	border: 2px solid #111;
	color: #111;
	padding: 10px 30px;
	background: none
}

a.btn_1.outline:hover,
.btn_1.outline:hover {
	border-color: #004dda;
	color: #004dda !important
}

a.btn_1.outline:focus,
.btn_1.outline:focus {
	outline: none
}

a.btn_1.gray,
.btn_1.gray {
	background: #f0f0f0;
	color: #111 !important
}

a.btn_1.gray:hover,
.btn_1.gray:hover {
	background-color: #FFC107;
	color: #111 !important
}

a.btn_1 i,
.btn_1 i {
	font-weight: 600
}

main {
	background-color: #fff;
	z-index: 2;
	position: relative
}

main.freeze {
	z-index: inherit
}

.container {
	max-width: 1220px
}

@media (max-width: 991px) {
	.container {
		max-width: 100%
	}
}

.top_line {
	padding: 12px 0 10px 0;
	line-height: 1
}

.top_line.promo {
	background-color: #004dda;
	text-align: center;
	color: #fff;
	font-size: 14px;
	font-size: 0.875rem;
	font-weight: 500
}

.top_line.promo strong {
	color: #ffff99;
	font-weight: 700;
	text-transform: uppercase
}

.top_line.version_1 {
	color: #fff;
	background-color: #004dda;
	font-size: 13px;
	font-size: 0.8125rem
}

@media (max-width: 767px) {
	.top_line.version_1 {
		display: none
	}
}

.top_line.version_1.plus_select {
	padding: 7px 0
}

.top_line.version_1.plus_select .styled-select select {
	color: #fff
}

.top_line.version_1.plus_select .styled-select:after {
	color: #fff
}

.top_line.version_1 ul.top_links {
	list-style: none;
	margin: 0;
	float: right
}

.top_line.version_1 ul.top_links li {
	display: inline-block;
	position: relative;
	margin-left: 5px
}

.top_line.version_1 ul.top_links li:first-child {
	margin-left: 0
}

header .main_header {
	position: relative;
	z-index: 99999
}

@media (max-width: 991px) {
	header .main_header {
		padding: 15px 0
	}
}

@media (max-width: 991px) {
	header .main_header #logo {
		text-align: center
	}
	header .main_header #logo a img {
		height: 30px;
		width: auto
	}
}

header .main_header a.phone_top {
	display: inline-block;
	line-height: 1.3
}

header .main_header a.phone_top strong span {
	display: block;
	font-weight: 900
}

@media (max-width: 991px) {
	header .main_header a.phone_top {
		position: absolute;
		right: 0;
		top: -27px;
		z-index: 2
	}
	header .main_header a.phone_top strong {
		display: none
	}
	header .main_header a.phone_top:before {
		font-family: "fontello";
		font-size: 26px;
		font-size: 1.625rem;
		content: '\e802';
		line-height: 1;
		padding: 0;
		margin: 0;
		display: inline-block
	}
}

header .main_header nav a.open_close {
	position: absolute;
	top: -26px;
	left: 5px;
	display: none
}

@media (max-width: 991px) {
	header .main_header nav a.open_close {
		display: inline-block
	}
}

header ul.top_tools {
	margin: 0;
	padding: 0;
	list-style: none;
	float: right
}

@media (max-width: 767px) {
	header ul.top_tools {
		float: none;
		width: 100%
	}
}

header ul.top_tools>li {
	float: right;
	margin-left: 20px
}

header ul.top_tools>li:nth-child(4),
header ul.top_tools>li:nth-child(5) {
	display: none
}

@media (max-width: 767px) {
	header ul.top_tools>li:nth-child(4),
	header ul.top_tools>li:nth-child(5) {
		display: inline-block
	}
}

@media (max-width: 767px) {
	header ul.top_tools>li:last-child {
		float: left;
		margin-left: 0
	}
}

header ul.top_tools>li a {
	display: block;
	position: relative;
	height: 60px;
	line-height: 60px
}

@media (max-width: 767px) {
	header ul.top_tools>li a {
		height: 28px;
		line-height: 28px
	}
}

header ul.top_tools>li a>span {
	display: none
}

header ul.top_tools>li a:before {
	font-family: 'header_icons';
	font-size: 26px;
	font-size: 1.625rem;
	font-weight: 500 !important
}

header ul.top_tools>li a.btn_search_mob:before,
header ul.top_tools>li a.search_panel:before {
	content: '\0045';
	font-size: 26px;
	font-size: 1.625rem
}

header ul.top_tools>li a.btn_cat_mob {
	line-height: 32px !important;
	height: 28px !important;
	padding-left: 35px;
	font-weight: 700;
	font-size: 13px;
	font-size: 0.8125rem;
	text-transform: uppercase
}

@media (max-width: 991px) {
	header ul.top_tools>li a.btn_cat_mob {
		padding-left: 0
	}
}

header ul.top_tools>li a.btn_cat_mob .hamburger-box {
	position: absolute;
	top: 5px;
	left: 0;
	margin-right: 10px
}

@media (max-width: 991px) {
	header ul.top_tools>li a.btn_cat_mob .hamburger-box {
		display: none
	}
}

header ul.top_tools>li a.wishlist:before {
	content: '\0041'
}

header ul.top_tools>li a.access_link:before {
	content: '\0043'
}

header ul.top_tools>li a.cart_bt:before {
	content: '\0042'
}

header ul.top_tools>li a.cart_bt strong {
	font-size: 10px;
	font-size: 0.625rem;
	font-weight: 700;
	width: 16px;
	height: 16px;
	text-indent: 0;
	display: block;
	text-align: center;
	position: absolute;
	bottom: 10px;
	right: -3px;
	line-height: 17px !important;
	border-radius: 50%
}

@media (max-width: 767px) {
	header ul.top_tools>li a.cart_bt strong {
		bottom: -3px
	}
}

header ul.top_tools>li .dropdown-cart .dropdown-menu,
header ul.top_tools>li .dropdown-access .dropdown-menu {
	border: none;
	min-width: 0;
	padding: 15px;
	width: 260px;
	top: 100%;
	border-radius: 0;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.175);
	font-size: 13px;
	font-size: 0.8125rem
}

@media (max-width: 991px) {
	header ul.top_tools>li .dropdown-cart .dropdown-menu,
	header ul.top_tools>li .dropdown-access .dropdown-menu {
		display: none !important
	}
}

header ul.top_tools>li .dropdown-cart .dropdown-menu:before,
header ul.top_tools>li .dropdown-access .dropdown-menu:before {
	bottom: 100%;
	border: solid transparent;
	content: " ";
	height: 0;
	width: 0;
	position: absolute;
	pointer-events: none;
	border-width: 7px;
	margin-left: -7px
}

header ul.top_tools>li .dropdown-cart .dropdown-menu a,
header ul.top_tools>li .dropdown-access .dropdown-menu a {
	height: auto;
	line-height: inherit
}

header ul.top_tools>li .dropdown-cart .dropdown-menu a.btn_1,
header ul.top_tools>li .dropdown-access .dropdown-menu a.btn_1 {
	color: #111;
	background-color: #FFC107;
	height: auto;
	line-height: 1.4
}

header ul.top_tools>li .dropdown-cart .dropdown-menu a.btn_1.outline,
header ul.top_tools>li .dropdown-access .dropdown-menu a.btn_1.outline {
	background-color: transparent
}

header ul.top_tools>li .dropdown-cart .dropdown-menu a.btn_1:hover,
header ul.top_tools>li .dropdown-access .dropdown-menu a.btn_1:hover {
	color: #fff !important;
	background-color: #004dda
}

header .main_nav {
	position: relative;
	z-index: 999
}

@media (max-width: 767px) {
	header .main_nav {
		padding: 10px 0
	}
}

header .main_nav .custom-search-input {
	padding: 0;
	width: 100%;
	margin: 0;
	top: 10px;
	position: relative;
	height: 40px;
	border-radius: 3px
}

header .main_nav .custom-search-input input[type='text'] {
	width: 100%;
	height: 40px;
	border: 0;
	background-color: transparent;
	padding: 15px
}

header .main_nav .custom-search-input input[type='text']:focus {
	outline: none
}

@media (max-width: 575px) {
	header .main_nav .custom-search-input input[type='text'] {
		padding-left: 15px
	}
}

header .main_nav .custom-search-input button[type='submit'] {
	position: absolute;
	transition: all 0.3s ease-in-out;
	font-size: 24px;
	font-size: 1.5rem;
	top: 5px;
	right: 3px;
	border: 0;
	cursor: pointer;
	outline: none;
	background: none
}

header .main_nav .custom-search-input button[type='submit']:hover {
	color: #004dda
}

header .sticky_element {
	width: 100%;
	position: fixed;
	left: 0;
	top: 0;
	z-index: 9999;
	-webkit-animation: smoothScroll 0.5s none;
	border-bottom: 1px solid transparent !important;
	box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25)
}

@-webkit-keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-60px);
		        transform: translateY(-60px)
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px)
	}
}

@keyframes smoothScroll {
	0% {
		-webkit-transform: translateY(-60px);
		        transform: translateY(-60px)
	}
	100% {
		-webkit-transform: translateY(0px);
		        transform: translateY(0px)
	}
}

header.version_1 .main_header {
	background-color: #004dda
}

header.version_1 .main_header a.phone_top {
	color: #fff
}

header.version_1 .main_header a.phone_top:before {
	color: #fff
}

@media (max-width: 767px) {
	header.version_1 .main_header ul.top_tools {
		width: auto
	}
}

header.version_1 .main_header ul.top_tools>li:last-child {
	display: inline-block
}

@media (max-width: 767px) {
	header.version_1 .main_header ul.top_tools>li:last-child {
		float: none
	}
}

@media (max-width: 1199px) {
	header.version_1 .main_header ul.top_tools>li:nth-child(2) {
		display: none
	}
}

@media (max-width: 991px) {
	header.version_1 .main_header ul.top_tools>li:nth-child(3) {
		display: none
	}
}

header.version_1 .main_header ul.top_tools>li a {
	color: #fff
}

@media (max-width: 767px) {
	header.version_1 .main_header ul.top_tools>li a {
		line-height: 60px;
		height: 60px
	}
}

header.version_1 .main_header ul.top_tools>li a:hover {
	color: #ffff99
}

header.version_1 .main_header ul.top_tools>li a.cart_bt strong {
	background-color: #083487;
	color: #fff !important
}

@media (max-width: 767px) {
	header.version_1 .main_header ul.top_tools>li a.cart_bt strong {
		bottom: 10px
	}
}

header.version_1 .main_header ul.top_tools>li .dropdown-cart .dropdown-menu,
header.version_1 .main_header ul.top_tools>li .dropdown-access .dropdown-menu {
	border-top: 2px solid #004dda
}

header.version_1 .main_header ul.top_tools>li .dropdown-cart .dropdown-menu:before,
header.version_1 .main_header ul.top_tools>li .dropdown-access .dropdown-menu:before {
	border-bottom-color: #004dda
}

header.version_1 .main_header ul.top_tools>li .dropdown-cart:hover>a,
header.version_1 .main_header ul.top_tools>li .dropdown-access:hover>a {
	color: #ffff99
}

@media (max-width: 991px) {
	header.version_1 .main_header ul.top_tools {
		position: absolute;
		top: -43px;
		right: 0
	}
}

header.version_1 .main_nav {
	background-color: #fff
}

header.version_1 .main_nav.inner {
	border-bottom: 1px solid #ededed
}

header.version_1 .main_nav .custom-search-input {
	background-color: #f2f2f2
}

header.version_1 .main_nav ul.top_tools>li a {
	color: #111
}

header.version_1 .main_nav ul.top_tools>li a:hover {
	color: #004dda
}

header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob .hamburger-inner,
header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob .hamburger-inner::before,
header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob .hamburger-inner::after {
	background-color: #111
}

header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob:hover .hamburger-inner,
header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob:hover .hamburger-inner::before,
header.version_1 .main_nav ul.top_tools>li a.btn_cat_mob:hover .hamburger-inner::after {
	background-color: #004dda
}

header.version_1 .main_nav ul.top_tools>li a.cart_bt strong {
	background-color: #083487;
	color: #fff !important
}

header.version_1 .main_nav ul.top_tools>li .dropdown-cart .dropdown-menu,
header.version_1 .main_nav ul.top_tools>li .dropdown-access .dropdown-menu {
	border-top: 2px solid #004dda;
	margin-top: 0
}

header.version_1 .main_nav ul.top_tools>li .dropdown-cart .dropdown-menu:before,
header.version_1 .main_nav ul.top_tools>li .dropdown-access .dropdown-menu:before {
	border-bottom-color: #004dda
}

header.version_1 .main_nav ul.top_tools>li .dropdown-cart:hover>a,
header.version_1 .main_nav ul.top_tools>li .dropdown-access:hover>a {
	color: #004dda
}

header.version_2 .main_header {
	background-color: #fff
}

header.version_2 .main_header .hamburger-inner,
header.version_2 .main_header .hamburger-inner::before,
header.version_2 .main_header .hamburger-inner::after {
	background-color: #111
}

header.version_2 .main_header:hover .hamburger-inner,
header.version_2 .main_header:hover .hamburger-inner::before,
header.version_2 .main_header:hover .hamburger-inner::after {
	background-color: #004dda
}

header.version_2 .main_header a.phone_top {
	color: #444
}

header.version_2 .main_header a.phone_top:before {
	color: #444
}

@media (max-width: 767px) {
	header.version_2 .main_header ul.top_tools {
		width: auto
	}
}

header.version_2 .main_header ul.top_tools>li:last-child {
	display: inline-block
}

@media (max-width: 767px) {
	header.version_2 .main_header ul.top_tools>li:last-child {
		float: none
	}
}

@media (max-width: 1199px) {
	header.version_2 .main_header ul.top_tools>li:nth-child(2) {
		display: none
	}
}

@media (max-width: 991px) {
	header.version_2 .main_header ul.top_tools>li:nth-child(3) {
		display: none
	}
}

header.version_2 .main_header ul.top_tools>li a {
	color: #444
}

@media (max-width: 767px) {
	header.version_2 .main_header ul.top_tools>li a {
		line-height: 60px;
		height: 60px
	}
}

header.version_2 .main_header ul.top_tools>li a:hover {
	color: #004dda
}

header.version_2 .main_header ul.top_tools>li a.cart_bt strong {
	background-color: #083487;
	color: #fff !important
}

@media (max-width: 767px) {
	header.version_2 .main_header ul.top_tools>li a.cart_bt strong {
		bottom: 10px
	}
}

header.version_2 .main_header ul.top_tools>li .dropdown-cart .dropdown-menu,
header.version_2 .main_header ul.top_tools>li .dropdown-access .dropdown-menu {
	border-top: none
}

header.version_2 .main_header ul.top_tools>li .dropdown-cart .dropdown-menu:before,
header.version_2 .main_header ul.top_tools>li .dropdown-access .dropdown-menu:before {
	border-bottom-color: transparent
}

header.version_2 .main_header ul.top_tools>li .dropdown-cart:hover>a,
header.version_2 .main_header ul.top_tools>li .dropdown-access:hover>a {
	color: #004dda
}

@media (max-width: 991px) {
	header.version_2 .main_header ul.top_tools {
		position: absolute;
		top: -43px;
		right: 0
	}
}

header.version_2 .main_nav {
	background-color: #004dda;
	border-bottom: 1px solid transparent
}

header.version_2 .main_nav .custom-search-input {
	background-color: #f8f8f8
}

header.version_2 .main_nav ul.top_tools>li a {
	color: #fff
}

header.version_2 .main_nav ul.top_tools>li a:hover {
	color: #ffff99
}

header.version_2 .main_nav ul.top_tools>li a.cart_bt strong {
	background-color: #083487;
	color: #fff !important
}

header.version_2 .main_nav ul.top_tools>li .dropdown-cart:hover>a,
header.version_2 .main_nav ul.top_tools>li .dropdown-access:hover>a {
	color: #ffff99
}

header.version_2 .search_mob_wp .form-control {
	border: none
}

header.version_2 .search_mob_wp .btn_1 {
	background-color: #FFC107;
	color: #111
}

header.version_2 .search_mob_wp .btn_1:hover {
	background-color: #083487;
	color: #fff !important
}

@media only screen and (min-width: 992px) {
	.menu {
		display: block !important
	}
	#mm-menu,
	.mm-menu {
		display: none !important
	}
}

.mm-menu {
	background: #fff !important
}

.mm-listview a {
	color: #444
}

.main-menu {
	position: relative;
	z-index: 9;
	width: auto
}

.main-menu ul,
.main-menu ul li,
.main-menu ul li a {
	position: relative;
	margin: 0;
	padding: 0
}

.main-menu ul li a {
	display: block;
	padding: 3px 10px
}

.main-menu>ul>li>a {
	padding: 30px 18px 25px 18px;
	font-size: 13px;
	font-size: 0.8125rem;
	font-weight: 700;
	line-height: 1;
	text-transform: uppercase
}

header.version_1 .main-menu>ul>li>a {
	color: #fff
}

header.version_1 .main-menu>ul>li:hover>a {
	color: #fff
}

.main-menu>ul>li.submenu:hover>a:after {
	-webkit-transform: rotate(180deg);
	        transform: rotate(180deg)
}

.main-menu ul>li.submenu>a {
	transition: all 0.3s ease-in-out
}

.main-menu ul>li.submenu>a:after {
	font-family: 'themify';
	font-size: 12px;
	font-size: 0.75rem;
	content: "\e64b";
	color: #fff;
	line-height: 1;
	padding: 0;
	margin: 0 0 0 5px;
	font-weight: bold;
	display: inline-block;
	transition: all 0.3s ease-in-out
}

@media (max-width: 991px) {
	.main-menu ul>li.submenu>a:after {
		font-size: 14px !important;
		font-size: 0.875rem !important;
		float: right
	}
}

header.version_2 .main-menu>ul>li>a,
.main-menu>ul>li.submenu:hover>a {
	color: #282828
}

header.version_2 .main-menu>ul>li:hover>a {
	color: #004dda
}

header.version_2 .main-menu ul>li.submenu>a:after {
	color: #444
}

header.version_2 .main-menu>ul>li.submenu:hover>a:after {
	color: #004dda
}

#header_menu {
	display: none
}

nav a.open_close#close_in {
	position: absolute;
	right: 10px;
	left: inherit;
	top: 10px;
	width: 30px;
	height: 30px;
	display: none
}

nav a.open_close#close_in>i {
	color: #555 !important;
	font-size: 18px
}

nav a.open_close#close_in>i:hover {
	color: #1a1a1a !important
}

@media (max-width: 991px) {
	nav a.open_close#close_in {
		display: block
	}
}

@media only screen and (min-width: 992px) {
	.main-menu ul>li {
		display: inline-block
	}
	.main-menu ul ul,
	.main-menu ul li .menu-wrapper {
		position: absolute;
		z-index: 1;
		visibility: hidden;
		left: 0;
		top: 100%;
		display: block;
		background: #fff;
		display: block;
		min-width: 190px;
		-webkit-transform: scale(0.4);
		        transform: scale(0.4);
		-webkit-transform-origin: 10% top;
		        transform-origin: 10% top;
		transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s -webkit-transform cubic-bezier(0.1, 1.26, 0.83, 1);
		transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1);
		transition: 0.15s linear, 0.1s opacity cubic-bezier(0.39, 0.575, 0.565, 1), 0.15s transform cubic-bezier(0.1, 1.26, 0.83, 1), 0.15s -webkit-transform cubic-bezier(0.1, 1.26, 0.83, 1);
		box-shadow: 0px 6px 12px 0px rgba(0, 0, 0, 0.175);
		opacity: 0
	}
	.main-menu ul ul {
		padding: 10px
	}
	.main-menu ul li:hover>ul,
	.main-menu ul li:hover .menu-wrapper {
		visibility: visible;
		-webkit-transform: translateY(0);
		transform: translateY(0);
		opacity: 1
	}
	.main-menu ul .menu-wrapper ul {
		box-shadow: none;
		padding: 0;
		position: static;
		-webkit-transform: translateY(0);
		transform: translateY(0)
	}
	.main-menu ul ul li {
		display: block
	}
	.main-menu ul ul li a {
		color: #444;
		display: block;
		padding: 8px 10px
	}
	.main-menu ul ul li:hover>a {
		color: #004dda;
		background-color: #0044c2;
		background-color: rgba(0, 77, 218, 0.05)
	}
	.main-menu ul li.megamenu {
		position: static
	}
	.main-menu ul li.megamenu .menu-wrapper {
		width: 1110px;
		left: 50%;
		margin-left: -555px;
		padding: 30px !important
	}
	.main-menu ul li:hover.megamenu .menu-wrapper ul {
		visibility: visible;
		opacity: 1
	}
	.main-menu ul .menu-wrapper h3 {
		font-size: 14px;
		font-size: 0.875rem;
		text-transform: uppercase;
		font-weight: bold;
		padding-left: 10px
	}
	.main-menu ul ul ul {
		left: 100%;
		top: 0
	}
}

@media (min-width: 992px) and (max-width: 1199px) {
	.main-menu ul li.megamenu .menu-wrapper {
		width: 940px;
		margin-left: -510px
	}
}

@media only screen and (max-width: 991px) {
	#header_menu {
		text-align: center;
		padding: 25px 15px 10px 15px;
		position: relative;
		display: block;
		margin-bottom: 15px
	}
	#header_menu img {
		height: 30px !important;
		width: auto !important
	}
	.main-menu ul>li.submenu>a:after {
		color: #444;
		top: 5px;
		right: 15px;
		font-size: 32px;
		font-size: 2rem;
		font-weight: 400
	}
	.main-menu ul li a:hover,
	a.show-submenu:hover,
	a.show-submenu-mega:hover {
		color: #004dda !important;
		background-color: #0044c2;
		background-color: rgba(0, 77, 218, 0.05)
	}
	.main-menu ul li {
		border-bottom: 1px solid #ededed
	}
	.main-menu ul li>a {
		padding: 15px
	}
	.main-menu h3 {
		font-size: 12px;
		padding: 15px 15px 0 15px;
		text-transform: uppercase
	}
	.main-menu li,
	.main-menu a {
		display: block;
		color: #444 !important
	}
	.main-menu ul li.submenu ul {
		border-left: 1px solid #ededed;
		margin: 0 0 15px 25px
	}
	.main-menu ul li.submenu ul li {
		border: 0
	}
	.main-menu ul li.submenu ul li a {
		padding: 10px
	}
	.main-menu {
		overflow: auto;
		-webkit-transform: translateX(-200%);
		        transform: translateX(-200%);
		top: 0;
		left: -110%;
		bottom: 0;
		width: 45%;
		height: 100%;
		position: fixed;
		background-color: #fff;
		z-index: 9999;
		margin: 0;
		transition: all 0.5s cubic-bezier(0.77, 0, 0.175, 1)
	}
	.main-menu.show {
		-webkit-transform: translateX(0);
		        transform: translateX(0);
		left: 0
	}
	.main-menu .show-submenu+ul,
	a.show-submenu-mega+.menu-wrapper {
		display: none;
		visibility: hidden
	}
	a.show-submenu-mega+.menu-wrapper.show_mega,
	.main-menu a.show-submenu+ul.show_normal {
		display: block;
		visibility: visible
	}
}

@media only screen and (max-width: 580px) {
	.main-menu {
		width: 100%
	}
}

.dropdown-cart .dropdown-menu {
	margin-left: -205px
}

.dropdown-cart .dropdown-menu:before {
	right: 10%
}

.dropdown-cart .dropdown-menu .total_drop {
	font-size: 16px;
	font-size: 1rem
}

.dropdown-cart .dropdown-menu .total_drop strong {
	float: left
}

.dropdown-cart .dropdown-menu .total_drop span {
	float: right;
	font-weight: 600
}

.dropdown-cart .dropdown-menu .total_drop .btn_1 {
	margin-top: 10px
}

.dropdown-cart .dropdown-menu ul {
	padding: 0;
	list-style: none
}

.dropdown-cart .dropdown-menu ul li {
	position: relative
}

.dropdown-cart .dropdown-menu ul li a.action {
	color: #999;
	position: absolute;
	right: 0;
	top: 0;
	width: 15px;
	height: 15px
}

.dropdown-cart .dropdown-menu ul li a.action i {
	font-size: 16px;
	font-weight: normal;
	color: #777
}

.dropdown-cart .dropdown-menu ul li a.action i:hover {
	color: #1a1a1a
}

.dropdown-cart .dropdown-menu ul li a {
	border-bottom: 1px solid #ededed;
	margin: 0;
	padding: 0 15px 10px 60px;
	width: 100%;
	min-height: 60px;
	margin-bottom: 10px;
	position: relative
}

.dropdown-cart .dropdown-menu ul li a figure {
	width: 50px;
	height: 50px;
	overflow: hidden;
	position: absolute;
	margin: 0;
	left: 0;
	top: 0;
	border: 1px solid #ededed
}

.dropdown-cart .dropdown-menu ul li a figure img {
	width: auto;
	height: 50px;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1.1);
	transform: translate(-50%, -50%) scale(1.1)
}

.dropdown-cart .dropdown-menu ul li a strong {
	color: #444;
	margin-top: 5px;
	display: inline-block;
	color: #333;
	font-weight: 700
}

.dropdown-cart .dropdown-menu ul li a strong span {
	display: block;
	font-weight: normal
}

.dropdown-cart .dropdown-menu ul li a strong:hover {
	color: #004dda
}

.dropdown-access .dropdown-menu {
	margin-left: -115px
}

.dropdown-access .dropdown-menu:before {
	left: 50%
}

.dropdown-access .dropdown-menu ul {
	margin: 15px -15px -15px -15px;
	padding: 0
}

.dropdown-access .dropdown-menu ul li {
	display: block;
	font-size: 14px;
	font-size: 0.875rem;
	border-top: 1px solid #ededed
}

.dropdown-access .dropdown-menu ul li a {
	height: auto !important;
	color: #444 !important;
	border-left: 2px solid transparent;
	display: block;
	padding: 15px 15px 15px 50px !important;
	position: relative;
	line-height: 1
}

.dropdown-access .dropdown-menu ul li a i {
	font-size: 21px;
	font-size: 1.3125rem;
	margin-right: 10px;
	position: absolute;
	top: 14px;
	left: 15px
}

.dropdown-access .dropdown-menu ul li a:hover {
	border-left: 2px solid #004dda;
	color: #004dda !important;
	background-color: #0044c2;
	background-color: rgba(0, 77, 218, 0.04)
}

.search_mob_wp {
	padding: 10px 15px 0 15px;
	display: none;
	width: 100%;
	position: relative;
	top: 5px
}

.search_mob_wp .form-control {
	margin-bottom: 15px
}

.categories {
	display: none
}

.categories.menu {
	display: block
}

@media (max-width: 767px) {
	.categories {
		display: none
	}
}

.menu ul {
	position: relative;
	padding: 0;
	margin: 0;
	top: 0
}

.menu ul li {
	display: block;
	float: left
}

.menu ul li>span a {
	display: block;
	position: relative;
	float: left;
	font-size: 13px;
	font-size: 0.8125rem;
	font-weight: 700;
	padding-bottom: 0;
	text-decoration: none;
	height: 60px;
	line-height: 62px;
	text-transform: uppercase
}

.menu ul li>span a .hamburger-box {
	top: 10px;
	margin-right: 10px
}

.menu ul li ul {
	display: none;
	position: absolute;
	top: 100%;
	width: 300px;
	background: #fff;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.175);
	z-index: 99;
	border-top: 2px solid #004dda
}

.menu ul li ul li {
	display: block;
	width: 300px
}

.menu ul li ul li ul {
	border-top: none
}

.menu ul li ul li ul ul {
	background: #fff;
	border-top: none;
	width: 300px;
	margin-left: -1px;
	border-left: 1px solid #ddd;
	box-shadow: 5px 5px 5px 0px rgba(0, 0, 0, 0.175)
}

.menu ul li ul li ul ul:before {
	content: '';
	display: block;
	height: 2px;
	width: 100%;
	margin-top: -2px;
	background-color: #004dda
}

@media (max-width: 767px) {
	.menu ul li ul li {
		width: 100%
	}
}

.menu ul li ul li a {
	text-transform: none;
	display: block;
	float: none;
	font-weight: 400;
	font-size: 14px;
	font-size: 0.875rem;
	line-height: 45px;
	height: auto;
	padding: 0 10px 0 15px
}

.menu ul li ul li a:hover {
	background-color: #0044c2;
	background-color: rgba(0, 77, 218, 0.04)
}

.menu ul li ul li a.active {
	color: #004dda;
	background-color: #0044c2;
	background-color: rgba(0, 77, 218, 0.04)
}

.menu ul li ul li a.active:after {
	font-family: 'themify';
	content: "\e649";
	float: right;
	font-size: 21px;
	font-size: 1.3125rem;
	margin-top: 0;
	color: #004dda
}

@media (max-width: 767px) {
	.menu ul li ul li a.active:after {
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg)
	}
}

.menu ul.expanded span a.active:hover {
	color: #004dda;
	background-color: #0044c2;
	background-color: rgba(0, 77, 218, 0.04)
}

.menu ul.expanded span a.active:hover:after {
	font-family: 'themify';
	content: "\e649";
	float: right;
	font-size: 21px;
	font-size: 1.3125rem;
	margin-top: 0;
	color: #004dda
}

@media (max-width: 767px) {
	.menu ul.expanded span a.active:hover:after {
		-webkit-transform: rotate(90deg);
		        transform: rotate(90deg)
	}
}

.menu ul li ul.expanded {
	width: 600px
}

@media (max-width: 767px) {
	.menu ul li ul.expanded {
		width: 100%
	}
}

.menu ul li ul.expanded li {
	margin-right: 300px
}

@media (max-width: 767px) {
	.menu ul li ul.expanded li {
		margin-right: 0
	}
}

.menu ul li ul li ul {
	display: none;
	position: absolute;
	left: 300px;
	border-left: 1px solid #ddd;
	top: 0;
	height: 100%;
	background: none;
	box-shadow: none
}

@media (max-width: 767px) {
	.menu ul li ul li ul {
		position: relative;
		left: 0;
		border-left: 0
	}
}

.menu ul li ul li ul li a {
	color: #444
}

.menu ul li ul li ul li a:hover:after {
	content: none
}

ul li ul.fix_long {
	overflow: hidden;
	overflow-y: scroll
}

@media (max-width: 991px) {
	ul li ul.fix_long {
		overflow-y: auto
	}
}

ul li ul.fix_long:after {
	font-family: 'themify';
	content: "\e657";
	display: block;
	position: absolute;
	top: 5px;
	right: 5px;
	color: #999;
	font-size: 16px;
	font-size: 1rem
}

@media (max-width: 991px) {
	ul li ul.fix_long:after {
		display: none
	}
}

header.version_1 .menu ul li>span a {
	color: #111
}

header.version_1 .menu ul li>span a .hamburger-inner,
header.version_1 .menu ul li>span a .hamburger-inner::before,
header.version_1 .menu ul li>span a .hamburger-inner::after {
	background-color: #111
}

header.version_1 .menu ul li>span a:hover {
	color: #004dda
}

header.version_1 .menu ul li>span a:hover .hamburger-inner,
header.version_1 .menu ul li>span a:hover .hamburger-inner::before,
header.version_1 .menu ul li>span a:hover .hamburger-inner::after {
	background-color: #004dda
}

header.version_1 .menu ul li>span a.active {
	color: #004dda
}

header.version_1 .menu ul li>span a.active .hamburger-inner,
header.version_1 .menu ul li>span a.active .hamburger-inner::before,
header.version_1 .menu ul li>span a.active .hamburger-inner::after {
	background-color: #004dda
}

header.version_1 .menu ul li ul li a {
	color: #444
}

header.version_2 .menu ul li>span>a {
	color: #fff
}

header.version_2 .menu ul li>span>a .hamburger-inner,
header.version_2 .menu ul li>span>a .hamburger-inner::before,
header.version_2 .menu ul li>span>a .hamburger-inner::after {
	background-color: #fff
}

header.version_2 .menu ul li>span>a:hover {
	color: #ffff99
}

header.version_2 .menu ul li>span>a:hover .hamburger-inner,
header.version_2 .menu ul li>span>a:hover .hamburger-inner::before,
header.version_2 .menu ul li>span>a:hover .hamburger-inner::after {
	background-color: #ffff99
}

header.version_2 .menu ul li>span>a.active {
	color: #ffff99
}

header.version_2 .menu ul li>span>a.active .hamburger-inner,
header.version_2 .menu ul li>span>a.active .hamburger-inner::before,
header.version_2 .menu ul li>span>a.active .hamburger-inner::after {
	background-color: #ffff99
}

header.version_2 .menu ul li ul li a {
	color: #444 !important
}

header.version_2 .menu ul li ul li a:hover {
	color: #004dda !important
}

header.version_2 .menu ul li ul {
	top: 98%
}

header.version_2 .menu ul li ul li ul {
	top: 0
}

.top_banner {
	height: 250px;
	overflow: hidden;
	position: relative
}

@media (max-width: 767px) {
	.top_banner {
		height: 200px
	}
}

.top_banner .breadcrumbs {
	color: #fff
}

.top_banner .breadcrumbs ul li a {
	color: #fff;
	opacity: 0.6
}

.top_banner .breadcrumbs ul li a:hover {
	color: #fff;
	opacity: 1
}

.top_banner h1 {
	font-size: 32px;
	font-size: 2rem;
	font-weight: 900;
	text-transform: uppercase;
	color: #fff
}

@media (max-width: 767px) {
	.top_banner h1 {
		font-size: 22px;
		font-size: 1.375rem
	}
}

.top_banner img {
	z-index: 1;
	width: 100%;
	height: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%);
	transform: translate(-50%, -50%)
}

@media (max-width: 575px) {
	.top_banner img {
		height: 200px;
		width: auto !important;
		max-width: inherit !important
	}
}

.top_banner.version_2 h1 {
	padding: 10px 15px;
	background-color: #000;
	background-color: rgba(0, 0, 0, 0.8)
}

.top_banner.general {
	height: 450px
}

@media (max-width: 767px) {
	.top_banner.general {
		height: 300px
	}
}

.top_banner.general h1 {
	color: #111;
	text-transform: none;
	font-size: 42px;
	font-size: 2.625rem;
	font-weight: 500
}

@media (max-width: 767px) {
	.top_banner.general img {
		height: auto
	}
}

.owl-carousel {
	display: none;
	width: 100%;
	-webkit-tap-highlight-color: transparent;
	position: relative;
	z-index: 1
}

.owl-carousel .owl-stage {
	position: relative;
	touch-action: manipulation;
	-moz-backface-visibility: hidden
}

.owl-carousel .owl-stage:after {
	content: ".";
	display: block;
	clear: both;
	visibility: hidden;
	line-height: 0;
	height: 0
}

.owl-carousel .owl-stage-outer {
	position: relative;
	overflow: hidden;
	-webkit-transform: translate3d(0px, 0px, 0px)
}

.owl-carousel .owl-wrapper,
.owl-carousel .owl-item {
	-webkit-backface-visibility: hidden;
	-moz-backface-visibility: hidden;
	-ms-backface-visibility: hidden;
	-webkit-transform: translate3d(0, 0, 0);
	-moz-transform: translate3d(0, 0, 0);
	-ms-transform: translate3d(0, 0, 0)
}

.owl-carousel .owl-item {
	position: relative;
	min-height: 1px;
	float: left;
	-webkit-backface-visibility: hidden;
	-webkit-tap-highlight-color: transparent;
	-webkit-touch-callout: none
}

.owl-carousel .owl-item img {
	display: block;
	width: 100%
}

.owl-carousel .owl-nav.disabled,
.owl-carousel .owl-dots.disabled {
	display: none
}

.owl-carousel .owl-nav .owl-prev,
.owl-carousel .owl-nav .owl-next,
.owl-carousel .owl-dot {
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
	background: none;
	color: inherit;
	border: none;
	padding: 0 !important;
	font: inherit
}

.owl-carousel.owl-loaded {
	display: block
}

.owl-carousel.owl-loading {
	opacity: 0;
	display: block
}

.owl-carousel.owl-hidden {
	opacity: 0
}

.owl-carousel.owl-refresh .owl-item {
	visibility: hidden
}

.owl-carousel.owl-drag .owl-item {
	touch-action: pan-y;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.owl-carousel.owl-grab {
	cursor: move;
	cursor: -webkit-grab;
	cursor: grab
}

.owl-carousel.owl-rtl {
	direction: rtl
}

.owl-carousel.owl-rtl .owl-item {
	float: right
}

.no-js .owl-carousel {
	display: block
}

.owl-carousel .animated {
	-webkit-animation-duration: 1000ms;
	        animation-duration: 1000ms;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both
}

.owl-carousel .owl-animated-in {
	z-index: 0
}

.owl-carousel .owl-animated-out {
	z-index: 1
}

.owl-carousel .fadeOut {
	-webkit-animation-name: fadeOut;
	        animation-name: fadeOut
}

@-webkit-keyframes fadeOut {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}

@keyframes fadeOut {
	0% {
		opacity: 1
	}
	100% {
		opacity: 0
	}
}

.owl-carousel .owl-item .owl-lazy {
	opacity: 0;
	transition: opacity 400ms ease
}

.owl-carousel .owl-item .owl-lazy[src^=""],
.owl-carousel .owl-item .owl-lazy:not([src]) {
	max-height: 0
}

.owl-carousel .owl-item img.owl-lazy {
	-webkit-transform-style: preserve-3d;
	        transform-style: preserve-3d
}

.owl-theme .owl-nav {
	margin-top: 10px;
	text-align: center;
	-webkit-tap-highlight-color: transparent
}

.owl-theme .owl-nav [class*='owl-'] {
	color: #FFF;
	font-size: 14px;
	margin: 5px;
	padding: 4px 7px;
	background: #D6D6D6;
	display: inline-block;
	cursor: pointer;
	border-radius: 3px
}

.owl-theme .owl-nav [class*='owl-']:hover {
	background: #869791;
	color: #FFF;
	text-decoration: none
}

.owl-theme .owl-nav .disabled {
	opacity: 0.5;
	cursor: default
}

.owl-theme .owl-nav.disabled+.owl-dots {
	margin-top: 10px
}

.owl-theme .owl-dots {
	text-align: center;
	-webkit-tap-highlight-color: transparent
}

.owl-theme .owl-dots .owl-dot {
	display: inline-block;
	zoom: 1;
	*display: inline
}

.owl-theme .owl-dots .owl-dot span {
	width: 10px;
	height: 10px;
	margin: 5px 7px;
	background: #D6D6D6;
	display: block;
	-webkit-backface-visibility: visible;
	transition: opacity 200ms ease;
	border-radius: 30px
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: #869791
}

.mm-hidden {
	display: none !important
}

.mm-wrapper {
	overflow-x: hidden;
	position: relative
}

.mm-menu {
	box-sizing: border-box;
	background: inherit;
	display: block;
	padding: 0;
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0
}

.mm-panels,
.mm-panels>.mm-panel {
	background: inherit;
	border-color: inherit;
	box-sizing: border-box;
	margin: 0;
	position: absolute;
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 0
}

.mm-panels {
	overflow: hidden
}

.mm-panel {
	-webkit-transform: translate(100%, 0);
	transform: translate(100%, 0);
	-webkit-transform: translate3d(100%, 0, 0);
	transform: translate3d(100%, 0, 0);
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	-webkit-transform-origin: top left;
	transform-origin: top left
}

.mm-panel.mm-opened {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.mm-panel.mm-subopened {
	-webkit-transform: translate(-30%, 0);
	transform: translate(-30%, 0);
	-webkit-transform: translate3d(-30%, 0, 0);
	transform: translate3d(-30%, 0, 0)
}

.mm-panel.mm-highest {
	z-index: 1
}

.mm-panel.mm-noanimation {
	transition: none !important
}

.mm-panel.mm-noanimation.mm-subopened {
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.mm-panels>.mm-panel {
	-webkit-overflow-scrolling: touch;
	overflow: scroll;
	overflow-x: hidden;
	overflow-y: auto;
	padding: 0 20px
}

.mm-panels>.mm-panel.mm-hasnavbar {
	padding-top: 40px
}

.mm-panels>.mm-panel:not(.mm-hidden) {
	display: block
}

.mm-panels>.mm-panel:after,
.mm-panels>.mm-panel:before {
	content: '';
	display: block;
	height: 20px
}

.mm-vertical .mm-panel {
	-webkit-transform: none !important;
	transform: none !important
}

.mm-listview .mm-vertical .mm-panel,
.mm-vertical .mm-listview .mm-panel {
	display: none;
	padding: 10px 0 10px 10px
}

.mm-listview .mm-vertical .mm-panel .mm-listview>li:last-child:after,
.mm-vertical .mm-listview .mm-panel .mm-listview>li:last-child:after {
	border-color: transparent
}

.mm-vertical li.mm-opened>.mm-panel,
li.mm-vertical.mm-opened>.mm-panel {
	display: block
}

.mm-listview>li.mm-vertical>.mm-next,
.mm-vertical .mm-listview>li>.mm-next {
	box-sizing: border-box;
	height: 40px;
	bottom: auto
}

.mm-listview>li.mm-vertical.mm-opened>.mm-next:after,
.mm-vertical .mm-listview>li.mm-opened>.mm-next:after {
	-webkit-transform: rotate(225deg);
	transform: rotate(225deg);
	right: 19px
}

.mm-btn {
	box-sizing: border-box;
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	z-index: 1
}

.mm-clear:after,
.mm-clear:before,
.mm-close:after,
.mm-close:before {
	content: '';
	border: 2px solid transparent;
	display: block;
	width: 5px;
	height: 5px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0;
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg)
}

.mm-clear:before,
.mm-close:before {
	border-right: none;
	border-bottom: none;
	right: 18px
}

.mm-clear:after,
.mm-close:after {
	border-left: none;
	border-top: none;
	right: 25px
}

.mm-next:after,
.mm-prev:before {
	content: '';
	border-top: 2px solid transparent;
	border-left: 2px solid transparent;
	display: block;
	width: 8px;
	height: 8px;
	margin: auto;
	position: absolute;
	top: 0;
	bottom: 0
}

.mm-prev:before {
	-webkit-transform: rotate(-45deg);
	transform: rotate(-45deg);
	left: 23px;
	right: auto
}

.mm-next:after {
	-webkit-transform: rotate(135deg);
	transform: rotate(135deg);
	right: 23px;
	left: auto
}

.mm-navbar {
	border-bottom: 1px solid;
	border-color: inherit;
	text-align: center;
	line-height: 20px;
	height: 40px;
	padding: 0 40px;
	margin: 0;
	position: absolute;
	top: 0;
	left: 0;
	right: 0
}

.mm-navbar>* {
	display: block;
	padding: 10px 0
}

.mm-navbar a,
.mm-navbar a:hover {
	text-decoration: none
}

.mm-navbar .mm-title {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden
}

.mm-navbar .mm-btn:first-child {
	left: 0
}

.mm-navbar .mm-btn:last-child {
	text-align: right;
	right: 0
}

.mm-panel .mm-navbar {
	display: none
}

.mm-panel.mm-hasnavbar .mm-navbar {
	display: block
}

.mm-listview,
.mm-listview>li {
	list-style: none;
	display: block;
	padding: 0;
	margin: 0
}

.mm-listview {
	font: inherit;
	font-size: 14px;
	line-height: 20px
}

.mm-listview a,
.mm-listview a:hover {
	text-decoration: none
}

.mm-listview>li {
	position: relative
}

.mm-listview>li,
.mm-listview>li .mm-next,
.mm-listview>li .mm-next:before,
.mm-listview>li:after {
	border-color: inherit
}

.mm-listview>li>a,
.mm-listview>li>span {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	color: inherit;
	display: block;
	padding: 10px 10px 10px 20px;
	margin: 0
}

.mm-listview>li:not(.mm-divider):after {
	content: '';
	border-bottom-width: 1px;
	border-bottom-style: solid;
	display: block;
	position: absolute;
	left: 0;
	right: 0;
	bottom: 0
}

.mm-listview>li:not(.mm-divider):after {
	left: 20px
}

.mm-listview .mm-next {
	background: rgba(3, 2, 1, 0);
	width: 50px;
	padding: 0;
	position: absolute;
	right: 0;
	top: 0;
	bottom: 0;
	z-index: 2
}

.mm-listview .mm-next:before {
	content: '';
	border-left-width: 1px;
	border-left-style: solid;
	display: block;
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0
}

.mm-listview .mm-next+a,
.mm-listview .mm-next+span {
	margin-right: 50px
}

.mm-listview .mm-next.mm-fullsubopen {
	width: 100%
}

.mm-listview .mm-next.mm-fullsubopen:before {
	border-left: none
}

.mm-listview .mm-next.mm-fullsubopen+a,
.mm-listview .mm-next.mm-fullsubopen+span {
	padding-right: 50px;
	margin-right: 0
}

.mm-panels>.mm-panel>.mm-listview {
	margin: 20px -20px
}

.mm-panels>.mm-panel>.mm-listview:first-child,
.mm-panels>.mm-panel>.mm-navbar+.mm-listview {
	margin-top: -20px
}

.mm-menu {
	background: #f3f3f3;
	border-color: rgba(0, 0, 0, 0.1);
	color: rgba(0, 0, 0, 0.75)
}

.mm-menu .mm-navbar a,
.mm-menu .mm-navbar>* {
	color: rgba(0, 0, 0, 0.3)
}

.mm-menu .mm-btn:after,
.mm-menu .mm-btn:before {
	border-color: rgba(0, 0, 0, 0.3)
}

.mm-menu .mm-listview {
	border-color: rgba(0, 0, 0, 0.1)
}

.mm-menu .mm-listview>li .mm-next:after {
	border-color: rgba(0, 0, 0, 0.3)
}

.mm-menu .mm-listview>li a:not(.mm-next) {
	-webkit-tap-highlight-color: rgba(255, 255, 255, 0.5)
}

.mm-menu .mm-listview>li.mm-selected>a:not(.mm-next),
.mm-menu .mm-listview>li.mm-selected>span {
	background: rgba(255, 255, 255, 0.5)
}

.mm-menu .mm-listview>li.mm-opened.mm-vertical>.mm-panel,
.mm-menu .mm-listview>li.mm-opened.mm-vertical>a.mm-next,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>.mm-panel,
.mm-menu.mm-vertical .mm-listview>li.mm-opened>a.mm-next {
	background: rgba(0, 0, 0, 0.05)
}

.mm-menu .mm-divider {
	background: rgba(0, 0, 0, 0.05)
}

.mm-page {
	box-sizing: border-box;
	position: relative
}

.mm-slideout {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	z-index: 1
}

html.mm-opened {
	overflow-x: hidden;
	position: relative
}

html.mm-blocking {
	overflow: hidden
}

html.mm-blocking body {
	overflow: hidden
}

html.mm-background .mm-page {
	background: inherit
}

#mm-blocker {
	background: rgba(3, 2, 1, 0);
	display: none;
	width: 100%;
	height: 100%;
	position: fixed;
	top: 0;
	left: 0;
	z-index: 2
}

html.mm-blocking #mm-blocker {
	display: block
}

.mm-menu.mm-offcanvas {
	z-index: 0;
	display: none;
	position: fixed
}

.mm-menu.mm-offcanvas.mm-opened {
	display: block
}

.mm-menu.mm-offcanvas.mm-no-csstransforms.mm-opened {
	z-index: 10
}

.mm-menu.mm-offcanvas {
	width: 80%;
	min-width: 140px;
	max-width: 440px
}

html.mm-opening .mm-menu.mm-opened~.mm-slideout {
	-webkit-transform: translate(80%, 0);
	transform: translate(80%, 0);
	-webkit-transform: translate3d(80%, 0, 0);
	transform: translate3d(80%, 0, 0)
}

@media all and (max-width: 175px) {
	html.mm-opening .mm-menu.mm-opened~.mm-slideout {
		-webkit-transform: translate(140px, 0);
		transform: translate(140px, 0);
		-webkit-transform: translate3d(140px, 0, 0);
		transform: translate3d(140px, 0, 0)
	}
}

@media all and (min-width: 550px) {
	html.mm-opening .mm-menu.mm-opened~.mm-slideout {
		-webkit-transform: translate(440px, 0);
		transform: translate(440px, 0);
		-webkit-transform: translate3d(440px, 0, 0);
		transform: translate3d(440px, 0, 0)
	}
}

.mm-sronly {
	border: 0 !important;
	clip: rect(1px, 1px, 1px, 1px) !important;
	-webkit-clip-path: inset(50%) !important;
	clip-path: inset(50%) !important;
	white-space: nowrap !important;
	width: 1px !important;
	height: 1px !important;
	padding: 0 !important;
	overflow: hidden !important;
	position: absolute !important
}

.mm-menu.mm-autoheight {
	transition: none .4s ease;
	transition-property: height, -webkit-transform;
	transition-property: transform, height;
	transition-property: transform, height, -webkit-transform
}

.mm-menu.mm-autoheight:not(.mm-offcanvas) {
	position: relative
}

.mm-menu.mm-measureheight .mm-panel.mm-vertical.mm-opened,
.mm-menu.mm-measureheight .mm-panel:not(.mm-vertical) {
	display: block !important
}

.mm-menu.mm-measureheight .mm-panels>.mm-panel {
	bottom: auto !important;
	height: auto !important
}

.mm-navbars-bottom,
.mm-navbars-top {
	background: inherit;
	border-color: inherit;
	border-width: 0;
	overflow: hidden;
	position: absolute;
	left: 0;
	right: 0;
	z-index: 3
}

.mm-navbars-bottom>.mm-navbar,
.mm-navbars-top>.mm-navbar {
	border: none;
	padding: 0;
	position: relative;
	-webkit-transform: translate(0, 0);
	transform: translate(0, 0);
	-webkit-transform: translate3d(0, 0, 0);
	transform: translate3d(0, 0, 0)
}

.mm-navbars-top {
	border-bottom-style: solid;
	border-bottom-width: 1px;
	top: 0
}

.mm-navbars-bottom {
	border-top-style: solid;
	border-top-width: 1px;
	bottom: 0
}

.mm-navbar.mm-hasbtns {
	padding: 0 40px
}

.mm-navbar[class*=mm-navbar-content-]>* {
	box-sizing: border-box;
	display: block;
	float: left
}

.mm-navbar .mm-breadcrumbs {
	text-overflow: ellipsis;
	white-space: nowrap;
	overflow: hidden;
	-webkit-overflow-scrolling: touch;
	overflow-x: auto;
	text-align: left;
	padding: 0 0 0 17px
}

.mm-navbar .mm-breadcrumbs>* {
	display: inline-block;
	padding: 10px 3px
}

.mm-navbar .mm-breadcrumbs>a {
	text-decoration: underline
}

.mm-navbar.mm-hasbtns .mm-breadcrumbs {
	margin-left: -40px
}

.mm-navbar.mm-hasbtns .mm-btn:not(.mm-hidden)+.mm-breadcrumbs {
	margin-left: 0;
	padding-left: 0
}

.mm-hasnavbar-top-1 .mm-panels {
	top: 40px
}

.mm-hasnavbar-top-2 .mm-panels {
	top: 80px
}

.mm-hasnavbar-top-3 .mm-panels {
	top: 120px
}

.mm-hasnavbar-top-4 .mm-panels {
	top: 160px
}

.mm-hasnavbar-bottom-1 .mm-panels {
	bottom: 40px
}

.mm-hasnavbar-bottom-2 .mm-panels {
	bottom: 80px
}

.mm-hasnavbar-bottom-3 .mm-panels {
	bottom: 120px
}

.mm-hasnavbar-bottom-4 .mm-panels {
	bottom: 160px
}

.mm-navbar-size-2 {
	height: 80px
}

.mm-navbar-size-3 {
	height: 120px
}

.mm-navbar-size-4 {
	height: 160px
}

.mm-navbar-content-2>* {
	width: 50%
}

.mm-navbar-content-3>* {
	width: 33.33%
}

.mm-navbar-content-4>* {
	width: 25%
}

.mm-navbar-content-5>* {
	width: 20%
}

.mm-navbar-content-6>* {
	width: 16.67%
}

.mm-menu.mm-opened[class*=mm-pagedim]~#mm-blocker {
	opacity: 0
}

html.mm-opening .mm-menu.mm-opened[class*=mm-pagedim]~#mm-blocker {
	opacity: .3;
	transition: opacity 0.4s ease 0.4s
}

.mm-menu.mm-opened.mm-pagedim~#mm-blocker {
	background: inherit
}

.mm-menu.mm-opened.mm-pagedim-white~#mm-blocker {
	background: #fff
}

.mm-menu.mm-opened.mm-pagedim-black~#mm-blocker {
	background: #000
}

body.modal-open .mm-slideout {
	z-index: unset
}

#carousel-home,
#carousel-home-2 {
	position: relative
}

#carousel-home .static,
#carousel-home-2 .static {
	position: static
}

#carousel-home .cover,
#carousel-home-2 .cover {
	background-size: cover;
	background-position: center;
	background-repeat: no-repeat;
	position: absolute;
	left: 0;
	top: 0;
	bottom: 0;
	right: 0;
	max-height: none !important;
	z-index: 2;
	opacity: 1 !important
}

#carousel-home .owl-carousel,
#carousel-home-2 .owl-carousel {
	position: relative
}

#carousel-home .owl-carousel .owl-slide,
#carousel-home-2 .owl-carousel .owl-slide {
	height: 500px;
	position: relative
}

@media (max-width: 767px) {
	#carousel-home .owl-carousel .owl-slide,
	#carousel-home-2 .owl-carousel .owl-slide {
		height: 420px
	}
}

#carousel-home .owl-carousel .owl-slide-animated,
#carousel-home-2 .owl-carousel .owl-slide-animated {
	-webkit-transform: translateX(20px);
	transform: translateX(20px);
	opacity: 0;
	visibility: hidden;
	transition: all 0.05s ease
}

#carousel-home .owl-carousel .owl-slide-animated.is-transitioned,
#carousel-home-2 .owl-carousel .owl-slide-animated.is-transitioned {
	-webkit-transform: none;
	transform: none;
	opacity: 1;
	visibility: visible;
	transition: all 0.5s ease
}

#carousel-home .owl-carousel .owl-slide-title.is-transitioned,
#carousel-home-2 .owl-carousel .owl-slide-title.is-transitioned {
	transition-delay: 0.2s;
	-webkit-transition-delay: 0.2s;
	-moz-transition-delay: 0.2s;
	-o-transition-delay: 0.2s
}

#carousel-home .owl-carousel .owl-slide-subtitle.is-transitioned,
#carousel-home-2 .owl-carousel .owl-slide-subtitle.is-transitioned {
	transition-delay: 0.35s;
	-webkit-transition-delay: 0.35s;
	-moz-transition-delay: 0.35s;
	-o-transition-delay: 0.35s
}

#carousel-home .owl-carousel .owl-slide-cta.is-transitioned,
#carousel-home-2 .owl-carousel .owl-slide-cta.is-transitioned {
	transition-delay: 0.5s;
	-webkit-transition-delay: 0.5s;
	-moz-transition-delay: 0.5s;
	-o-transition-delay: 0.5s
}

#carousel-home .owl-carousel .owl-dots,
#carousel-home-2 .owl-carousel .owl-dots {
	bottom: 15px;
	width: 100%;
	position: absolute;
	z-index: 3
}

#carousel-home .owl-carousel .owl-dots .owl-dot,
#carousel-home .owl-carousel .owl-nav [class*="owl-"]:focus,
#carousel-home-2 .owl-carousel .owl-dots .owl-dot,
#carousel-home-2 .owl-carousel .owl-nav [class*="owl-"]:focus {
	outline: none
}

#carousel-home section,
#carousel-home-2 section {
	display: none
}

.slide-text {
	font-size: 21px;
	font-size: 1.3125rem
}

.slide-text h2,
.slide-text h3 {
	font-size: 46px;
	font-size: 2.875rem;
	font-weight: 900;
	text-transform: uppercase;
	line-height: 1
}

@media (max-width: 767px) {
	.slide-text h2,
	.slide-text h3 {
		font-size: 42px;
		font-size: 2.625rem
	}
}

.slide-text strong {
	font-weight: 700;
	font-size: 28px;
	font-size: 1.75rem
}

.slide-text.white {
	color: #fff
}

.slide-text.white h2,
.slide-text.white h3 {
	color: #fff
}

.slide-text.black {
	color: #111
}

.slide-text.black h2 {
	color: #000
}

#carousel-home-2 .owl-carousel .owl-slide {
	height: 600px
}

#carousel-home-2 .owl-carousel .owl-slide .static {
	position: static;
	-webkit-flex-direction: column;
	        flex-direction: column;
	height: 100%
}

#carousel-home-2 .owl-item.active.center {
	border-left: 5px solid #fff;
	border-right: 5px solid #fff
}

.feat {
	background-color: #f8f8f8;
	padding: 25px 0
}

@media (max-width: 767px) {
	.feat {
		display: none
	}
}

.feat ul {
	list-style: none;
	margin: 0 auto;
	padding: 0;
	text-align: center;
	width: 100%
}

.feat ul li {
	width: 33%;
	border-right: 1px solid #ddd;
	display: inline-block;
	position: relative;
	text-align: center
}

.feat ul li:last-child {
	border-right: 1px solid transparent
}

.feat ul li .box {
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center;
	-webkit-justify-content: center;
	        justify-content: center
}

.feat ul li h3 {
	font-size: 18px;
	font-size: 1.125rem;
	margin: 5px 0 0 0;
	padding: 0
}

.feat ul li i {
	font-size: 36px;
	font-size: 2.25rem;
	margin: 0 20px 0 0;
	color: #004dda
}

.feat ul li p {
	margin: 0
}

.grid_item {
	margin-bottom: 25px;
	text-align: center;
	position: relative;
	cursor: pointer
}

.grid_item:hover ul li {
	display: block
}

.grid_item figure {
	position: relative;
	overflow: hidden;
	transition: all 0.7s ease
}

.grid_item figure img:nth-child(1) {
	visibility: visible;
	opacity: 1;
	-webkit-transform: translateZ(0);
	        transform: translateZ(0)
}

.grid_item figure img:nth-child(2) {
	position: absolute;
	top: 0;
	left: 0;
	visibility: hidden;
	opacity: 0
}

.grid_item figure:hover {
	box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25)
}

.grid_item figure:hover img {
	transition: opacity 0.5s ease, -webkit-transform 1.5s cubic-bezier(0, 0, 0.44, 1.18);
	transition: opacity 0.5s ease, transform 1.5s cubic-bezier(0, 0, 0.44, 1.18);
	transition: opacity 0.5s ease, transform 1.5s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 1.5s cubic-bezier(0, 0, 0.44, 1.18);
	transition: opacity 0.5s ease, transform 1.5s cubic-bezier(0, 0, 0.44, 1.18), -webkit-transform 2s cubic-bezier(0, 0, 0.44, 1.18)
}

.grid_item figure:hover img:nth-child(2) {
	visibility: visible;
	opacity: 1;
	-webkit-transform: scale(1.05);
	        transform: scale(1.05)
}

.grid_item ul {
	margin: 0;
	padding: 0;
	list-style: none;
	position: absolute;
	right: 10px;
	top: 10px;
	font-size: 18px;
	font-size: 1.125rem;
	line-height: 1;
	z-index: 2
}

@media (max-width: 991px) {
	.grid_item ul {
		position: relative;
		left: 0px;
		right: inherit;
		margin-bottom: 10px;
		top: 0
	}
}

.grid_item ul li {
	margin-bottom: 5px;
	-webkit-animation: fadeIn 0.1s ease;
	        animation: fadeIn 0.1s ease;
	-webkit-animation-fill-mode: both;
	        animation-fill-mode: both;
	display: none;
	position: relative
}

@media (max-width: 991px) {
	.grid_item ul li {
		-webkit-animation: none;
		        animation: none;
		display: inline-block !important
	}
}

.grid_item ul li a {
	background-color: #fff;
	border-radius: 0;
	width: 36px;
	height: 36px;
	color: #444;
	text-align: center;
	line-height: 40px;
	display: block;
	margin: 0;
	padding: 0
}

.grid_item ul li a:hover {
	background-color: #004dda
}

.grid_item ul li a:hover i {
	color: #fff
}

.grid_item ul li a span {
	display: none
}

@media (max-width: 991px) {
	.grid_item ul li {
		display: block
	}
}

.grid_item ul li:nth-child(1) {
	-webkit-animation-delay: 0.05s;
	        animation-delay: 0.05s
}

.grid_item ul li:nth-child(2) {
	-webkit-animation-delay: 0.1s;
	        animation-delay: 0.1s
}

.grid_item ul li:nth-child(3) {
	-webkit-animation-delay: 0.15s;
	        animation-delay: 0.15s
}

.grid_item a h3 {
	font-size: 14px;
	font-size: 0.875rem;
	margin: 3px 0 0 0;
	color: #444;
	font-weight: 500;
	display: inline-block
}

.grid_item a h3:hover {
	color: #004dda
}

.grid_item .price_box {
	margin-bottom: 5px
}

.grid_item .price_box .old_price {
	font-size: 14px;
	font-size: 0.875rem
}

.grid_item .rating {
	display: block;
	margin-bottom: 0
}

.rating {
	color: #ccc;
	margin-bottom: 15px;
	display: inline-block
}

.rating i.voted {
	color: #fec348
}

.rating i:before {
	margin: 2px
}

.rating em a {
	display: inline-block;
	color: #999;
	margin-left: 10px;
	text-decoration: underline;
	display: inline-block
}

.rating em a:hover {
	color: #004dda
}

@-webkit-keyframes fadeIn {
	0% {
		opacity: 0;
		-webkit-transform: translateX(10px);
		transform: translateX(10px)
	}
	75% {
		opacity: 0.5
	}
	100% {
		opacity: 1;
		-webkit-transform: translateX(0);
		transform: translateX(0)
	}
}

.new_price {
	color: #004dda;
	font-weight: 500;
	font-size: 16px;
	font-size: 1rem
}

.old_price {
	color: #999;
	text-decoration: line-through;
	display: inline-block;
	white-space: nowrap;
	font-weight: 500;
	font-size: 16px;
	font-size: 1rem
}

.tooltip {
	line-height: 1.3;
	font-size: 11px;
	font-size: 0.6875rem;
	font-weight: 600
}

.tooltip.show {
	opacity: 0.8
}

.countdown {
	color: #fff;
	font-weight: 600;
	width: 100px;
	height: 22px;
	position: absolute;
	left: 50%;
	margin-left: -45px;
	bottom: 25px;
	z-index: 1;
	line-height: 23px;
	box-sizing: border-box;
	font-size: 12px;
	font-size: 0.75rem;
	background: #F33;
	text-align: center
}

@media (max-width: 575px) {
	.countdown {
		margin-left: -45px;
		width: 90px
	}
}

.countdown:before {
	position: absolute;
	left: -15px;
	top: -6px;
	content: '';
	/* background: url(../img/countdown_bg.svg) top left no-repeat; */
	width: 27px;
	height: 30px;
	display: inline-block
}

@media (max-width: 575px) {
	.countdown:before {
		display: none
	}
}

.percentage {
	background: #F33;
	color: #fff;
	border-radius: 3px;
	font-size: 12px;
	font-size: 0.75rem;
	line-height: 1;
	font-weight: 600;
	position: relative;
	padding: 5px 5px 3px 5px;
	top: -1px;
	margin-left: 10px;
	display: inline-block
}

.owl-carousel .owl-nav button.owl-prev,
.owl-carousel .owl-nav button.owl-next,
.owl-carousel button.owl-dot {
	outline: none
}

.owl-theme .owl-dots .owl-dot.active span,
.owl-theme .owl-dots .owl-dot:hover span {
	background: transparent !important;
	border: 2px solid #004dda;
	transition: all 0.3s ease-in-out
}

.owl-theme .owl-dots .owl-dot span {
	position: relative;
	border-radius: 50%;
	width: 16px !important;
	height: 16px !important;
	background: transparent !important;
	display: block;
	-webkit-backface-visibility: visible;
	transition: all .3s ease;
	border: 2px solid #ccc
}

.owl-theme .owl-dots .owl-dot span::before {
	content: '';
	background-color: #004dda !important;
	display: block;
	border-radius: 50%;
	width: 8px;
	height: 8px;
	position: absolute;
	left: 50%;
	top: 50%;
	margin-top: -4px;
	margin-left: -4px;
	opacity: 0;
	visibility: hidden;
	-webkit-transform: scale(0);
	        transform: scale(0);
	transition: all 0.3s ease-in-out
}

.owl-theme .owl-dots .owl-dot.active span::before {
	-webkit-transform: scale(1);
	        transform: scale(1);
	opacity: 1;
	visibility: visible
}

.owl-theme .owl-nav {
	position: absolute;
	top: 30%;
	-webkit-transform: translateY(-30%);
	        transform: translateY(-30%);
	left: 0;
	width: 100%;
	margin: 0 !important
}

.owl-prev,
.owl-next {
	width: 40px;
	height: 40px;
	position: absolute;
	top: 0;
	display: block !important;
	background: #fff !important;
	line-height: 46px !important;
	border: 1px solid #ededed !important
}

.owl-prev {
	left: -20px
}

.owl-next {
	right: -20px
}

.owl-prev i,
.owl-next i {
	font-size: 21px;
	font-size: 1.3125rem;
	color: #ccc;
	transition: all 0.3s ease-in-out
}

.owl-prev i:hover,
.owl-next i:hover {
	color: #004dda
}

.owl-prev.disabled i:hover,
.owl-next.disabled i:hover {
	color: #ccc
}

.layer {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	min-width: 100%;
	min-height: 100%;
	z-index: 999;
	background-color: rgba(0, 0, 0, 0.35);
	transition: all 0.1s ease;
	opacity: 0;
	visibility: hidden
}

.layer-is-visible {
	opacity: 1;
	visibility: visible
}

@media (max-width: 991px) {
	.products_carousel {
		margin-bottom: 20px
	}
}

@media (max-width: 991px) {
	.products_carousel .grid_item {
		margin-bottom: 0
	}
}

.search-input {
	padding: 0;
	width: 100%;
	margin: 0 0 30px 0;
	position: relative;
	border-radius: 3px
}

.search-input input[type='text'] {
	width: 100%;
	height: 50px;
	border: 0;
	border-radius: 3px;
	background-color: #eeeeee;
	padding: 15px
}

.search-input input[type='text']:focus {
	outline: none
}

@media (max-width: 575px) {
	.search-input input[type='text'] {
		padding-left: 15px
	}
}

.search-input button[type='submit'] {
	position: absolute;
	transition: all 0.3s ease-in-out;
	font-size: 24px;
	font-size: 1.5rem;
	top: 10px;
	right: 5px;
	border: 0;
	cursor: pointer;
	outline: none;
	background: none
}

.search-input button[type='submit']:hover {
	color: #004dda
}

.search-input.white input[type='text'] {
	background-color: #fff;
	border: 1px solid #ddd
}

.numbers-row {
	position: relative;
	width: 100%;
	height: 40px;
	overflow: visible;
	border: 1px solid #ddd;
	border-radius: 3px;
	background-color: #fff;
	text-align: left !important
}

input.qty2 {
	position: relative;
	width: 40px;
	height: 40px;
	text-align: center;
	left: 50%;
	background: none;
	padding: 5px;
	border: none;
	margin-left: -20px
}

input.qty2:focus {
	outline: none;
	box-shadow: none
}

.button_inc {
	cursor: pointer;
	position: absolute;
	width: 33px;
	height: 40px;
	line-height: 38px;
	text-align: center;
	z-index: 2;
	font-size: 26px;
	font-size: 1.625rem;
	font-weight: 300 !important;
	color: #999
}

.button_inc:hover {
	color: #004dda
}

.dec {
	left: 0;
	top: 0
}

.inc {
	right: 0;
	top: 0
}

.opacity-mask {
	width: 100%;
	height: 100%;
	position: absolute;
	left: 0;
	top: 0;
	z-index: 2
}

.ribbon {
	color: #fff;
	display: inline-block;
	font-size: 11px;
	font-size: 0.6875rem;
	left: 10px;
	line-height: 1;
	position: absolute;
	text-align: center;
	text-transform: uppercase;
	top: 10px;
	padding: 7px 10px;
	font-weight: 600;
	min-width: 45px;
	z-index: 1
}

.ribbon:before {
	border-style: solid solid solid none;
	border-width: 9px 9px 9px 0;
	bottom: -15px;
	content: "";
	left: 0;
	position: absolute;
	width: 9px
}

.ribbon.new {
	background-color: #9933cc
}

.ribbon.new:before {
	border-color: #9933cc transparent transparent
}

.ribbon.hot {
	background-color: #0C0
}

.ribbon.hot:before {
	border-color: #0C0 transparent transparent
}

.ribbon.off {
	background-color: #F33
}

.ribbon.off:before {
	border-color: #F33 transparent transparent
}

.box_general {
	padding: 25px 25px 5px 25px;
	margin-bottom: 25px;
	box-shadow: 0px 0px 15px 0px rgba(0, 0, 0, 0.05)
}

.bg_white {
	background-color: #fff
}

.bg_gray {
	background-color: #f8f8f8
}

.container_check {
	display: block;
	position: relative;
	padding-left: 30px;
	line-height: 1.7;
	margin-bottom: 8px;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.container_check input {
	position: absolute;
	opacity: 0;
	cursor: pointer
}

.container_check input:checked~.checkmark {
	background-color: #004dda;
	border: 1px solid transparent
}

.container_check .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #ddd;
	background-color: #fff;
	border-radius: 3px;
	transition: all 0.3s ease-in-out
}

.container_check .checkmark:after {
	content: "";
	position: absolute;
	display: none;
	left: 7px;
	top: 3px;
	width: 5px;
	height: 10px;
	border: solid white;
	border-width: 0 2px 2px 0;
	-webkit-transform: rotate(45deg);
	transform: rotate(45deg)
}

.container_check input:checked~.checkmark:after {
	display: block
}

.container_radio {
	display: block;
	position: relative;
	padding-left: 30px;
	line-height: 1.7;
	margin-bottom: 8px;
	cursor: pointer;
	-webkit-user-select: none;
	-ms-user-select: none;
	user-select: none
}

.container_radio input {
	position: absolute;
	opacity: 0
}

.container_radio input:checked~.checkmark:after {
	opacity: 1
}

.container_radio .checkmark {
	position: absolute;
	top: 0;
	left: 0;
	height: 20px;
	width: 20px;
	border: 1px solid #ddd;
	background-color: #fff;
	border-radius: 50%
}

.container_radio .checkmark:after {
	display: block;
	content: "";
	position: absolute;
	opacity: 0;
	transition: all 0.3s ease-in-out;
	top: 3px;
	left: 3px;
	width: 12px;
	height: 12px;
	border-radius: 50%;
	background: #004dda;
	transition: all 0.3s ease-in-out
}

.small-gutters {
	margin-right: -5px !important;
	margin-left: -5px !important
}

.small-gutters>[class*="col-"] {
	padding-right: 5px !important;
	padding-left: 5px !important
}

.form-control {
	padding: 10px;
	font-size: 14px;
	font-size: 0.875rem;
	border-radius: 3px;
	border: 1px solid #ddd;
	height: calc(1.75em + .75rem + 2px)
}

.form-control:focus {
	border-color: #ccc;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05)
}

.form-group {
	position: relative
}

ul.list_ok {
	list-style: none;
	margin: 0 0 25px 0;
	padding: 0
}

ul.list_ok li {
	position: relative;
	padding-left: 24px;
	margin-bottom: 5px
}

ul.list_ok li:before {
	font-family: 'themify';
	content: "\e64c";
	position: absolute;
	left: 0;
	top: -2px;
	font-size: 16px;
	font-size: 1rem;
	color: #1a1a1a
}

ul.list_ok li:last-child {
	margin-bottom: 0
}

.styled-select {
	width: 100%;
	overflow: hidden;
	height: 30px;
	line-height: 32px;
	border: none;
	background-color: #f3f3f3;
	background-color: rgba(255, 255, 255, 0.05);
	border-radius: 3px;
	padding-right: 35px;
	position: relative;
	color: #ccc;
	cursor: pointer
}

.styled-select:after {
	font-family: 'themify';
	color: #ccc;
	position: absolute;
	right: 10px;
	top: 0
}

.styled-select.lang-selector:after {
	content: "\e665"
}

.styled-select.currency-selector:after {
	content: "\e64b"
}

.styled-select select {
	background: transparent;
	width: 110%;
	padding-left: 10px;
	border: 0;
	box-shadow: none;
	height: 30px;
	color: #ccc;
	font-size: 12px;
	font-size: 0.75rem;
	margin: 0;
	font-weight: 500;
	-moz-appearance: window;
	-webkit-appearance: none;
	cursor: pointer;
	outline: none
}

.styled-select select:focus {
	color: #ccc;
	outline: none;
	box-shadow: none
}

.styled-select select::-ms-expand {
	display: none
}

.top_panel {
	background-color: #fff;
	position: fixed;
	top: -400px;
	left: 0;
	width: 100%;
	z-index: 99999;
	box-shadow: 0 20px 20px -20px rgba(0, 0, 0, 0.25)
}

.top_panel.show {
	top: 0;
	transition: all 0.3s ease
}

.top_panel .header_panel {
	position: relative;
	padding: 15px
}

.top_panel .header_panel label {
	font-weight: 500;
	font-size: 15px;
	font-size: 0.9375rem;
	position: relative;
	padding-left: 30px
}

.top_panel .header_panel label:before {
	font-family: 'themify';
	content: "\e64d";
	color: #66cc33;
	font-size: 24px;
	font-size: 1.5rem;
	font-weight: bold;
	position: absolute;
	left: 0;
	top: -7px
}

.top_panel .header_panel small {
	font-weight: 500;
	font-size: 15px;
	font-size: 0.9375rem
}

.top_panel .header_panel a.btn_close_top_panel {
	transition: all 0.3s ease-in-out;
	cursor: pointer;
	position: absolute;
	font-size: 24px;
	font-size: 1.5rem;
	top: 15px;
	right: 15px;
	color: #999;
	opacity: 0.8;
	width: 24px;
	height: 24px;
	line-height: 1
}

.top_panel .header_panel a.btn_close_top_panel:hover {
	-webkit-transform: rotate(90deg);
	transform: rotate(90deg);
	opacity: 1;
	color: #444
}

.top_panel .item {
	background-color: #f4f4f4;
	padding: 25px 0;
	min-height: 130px
}

.top_panel .item .btn_panel {
	text-align: right;
	padding-top: 25px
}

@media (max-width: 991px) {
	.top_panel .item .btn_panel {
		padding-top: 15px
	}
}

@media (max-width: 767px) {
	.top_panel .item .btn_panel {
		text-align: center
	}
}

.top_panel .item .btn_panel a {
	min-width: 200px
}

@media (max-width: 1199px) {
	.top_panel .item .btn_panel a {
		min-width: inherit
	}
}

@media (max-width: 767px) {
	.top_panel .item .btn_panel a {
		width: 49.2%
	}
}

.top_panel .related {
	padding: 15px 0 20px 15px
}

@media (max-width: 991px) {
	.top_panel .related {
		display: none
	}
}

.top_panel .related h4 {
	font-size: 13px;
	font-size: 0.8125rem;
	color: #999;
	margin: 5px 0 15px 0
}

.top_panel .related .item_panel a h5 {
	font-size: 14px;
	font-size: 0.875rem;
	color: #004dda;
	padding-top: 5px;
	margin: 0 0 3px 0;
	font-weight: 400;
	color: #444
}

.top_panel .related .item_panel a h5:hover {
	color: #004dda
}

.top_panel .related .item_panel figure {
	width: 60px;
	height: 60px;
	position: relative;
	overflow: hidden;
	border: 1px solid #ededed;
	padding: 0;
	border: 0
}

.top_panel .related .item_panel figure img {
	height: 60px;
	width: auto;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1.1);
	transform: translate(-50%, -50%) scale(1.1)
}

.top_panel .item_panel {
	position: relative;
	width: 100%
}

.top_panel .item_panel figure {
	width: 70px;
	height: 70px;
	overflow: hidden;
	position: relative;
	margin: 0 15px 0 0;
	float: left;
	border: 1px solid #ddd;
	padding: 10px;
	background-color: #fff;
	box-sizing: content-box
}

.top_panel .item_panel figure img {
	width: auto;
	height: 70px;
	position: absolute;
	left: 50%;
	top: 50%;
	-webkit-transform: translate(-50%, -50%) scale(1.1);
	transform: translate(-50%, -50%) scale(1.1)
}

.top_panel .item_panel h4 {
	font-size: 18px;
	font-size: 1.125rem;
	margin: 0 0 3px 0;
	font-weight: 400;
	padding-top: 15px;
	color: #444
}

@media (max-width: 991px) {
	.top_panel .item_panel h4 {
		padding-top: 0;
		font-size: 15px;
		font-size: 0.9375rem
	}
}

#toTop {
	position: fixed;
	right: 0;
	opacity: 0;
	visibility: hidden;
	bottom: 25px;
	margin: 0 25px 0 0;
	z-index: 999;
	transition: 0.35s;
	-webkit-transform: scale(0.7);
	        transform: scale(0.7);
	width: 46px;
	height: 46px;
	background-color: rgba(0, 0, 0, 0.6);
	opacity: 1;
	transition: all 0.3s;
	border-radius: 50%;
	text-align: center;
	font-size: 24px;
	font-size: 1.5rem;
	color: #fff;
	cursor: pointer
}

#toTop.visible {
	opacity: 1;
	visibility: visible;
	-webkit-transform: scale(1);
	        transform: scale(1)
}

#toTop:after {
	font-family: 'themify';
	content: "\e648";
	position: relative;
	display: block;
	top: 50%;
	-webkit-transform: translateY(-50%);
	transform: translateY(-50%)
}

#toTop:hover {
	background-color: #004dda
}

.modal-content {
	border: none !important;
	border-radius: 0 !important;
	padding: 25px
}

.nice-select {
	-webkit-tap-highlight-color: transparent;
	background-color: #fff;
	border-radius: 3px;
	border: none;
	border: 1px solid #ddd;
	box-sizing: border-box;
	clear: both;
	cursor: pointer;
	display: block;
	float: left;
	font-family: inherit;
	font-size: 14px;
	font-weight: normal;
	height: 38px;
	line-height: 34px;
	outline: none;
	padding-left: 10px;
	padding-right: 27px;
	position: relative;
	text-align: left !important;
	transition: all 0.2s ease-in-out;
	-webkit-user-select: none;
	    -ms-user-select: none;
	        user-select: none;
	white-space: nowrap;
	width: auto;
	color: #444;
	padding-top: 2px
}

.nice-select:active,
.nice-select.open,
.nice-select:focus {
	border-color: #ccc;
	outline: 0;
	box-shadow: 0 0 0 0.2rem rgba(0, 0, 0, 0.05)
}

.nice-select:after {
	border-bottom: 1px solid #777;
	border-right: 1px solid #777;
	content: '';
	display: block;
	height: 8px;
	margin-top: -5px;
	pointer-events: none;
	position: absolute;
	right: 15px;
	top: 50%;
	-webkit-transform-origin: 66% 66%;
	        transform-origin: 66% 66%;
	-webkit-transform: rotate(45deg);
	        transform: rotate(45deg);
	transition: all 0.15s ease-in-out;
	width: 8px
}

.nice-select.open:after {
	-webkit-transform: rotate(-135deg);
	        transform: rotate(-135deg)
}

.nice-select.open .list {
	opacity: 1;
	pointer-events: auto;
	-webkit-transform: scale(1) translateY(0);
	        transform: scale(1) translateY(0)
}

.nice-select.disabled {
	border-color: #9e9e9e;
	color: #757575;
	pointer-events: none
}

.nice-select.disabled:after {
	border-color: #ccc
}

.nice-select.wide {
	width: 100%
}

.nice-select.wide .list {
	left: 0 !important;
	right: 0 !important
}

.nice-select.right {
	float: right
}

.nice-select.right .list {
	left: auto;
	right: 0
}

.nice-select.small {
	font-size: 13px;
	height: 36px;
	line-height: 34px
}

.nice-select.small:after {
	height: 4px;
	width: 4px
}

.nice-select.small .option {
	line-height: 34px;
	min-height: 34px
}

.nice-select .list {
	width: 100%;
	background-color: #fff;
	border-radius: 3px;
	box-shadow: 0px 5px 10px 0px rgba(0, 0, 0, 0.175);
	box-sizing: border-box;
	margin-top: 4px;
	opacity: 0;
	overflow: hidden;
	padding: 0;
	pointer-events: none;
	position: absolute;
	top: 100%;
	left: 0;
	-webkit-transform-origin: 50% 0;
	        transform-origin: 50% 0;
	-webkit-transform: scale(0.75) translateY(-19px);
	        transform: scale(0.75) translateY(-19px);
	transition: all 0.2s cubic-bezier(0.5, 0, 0, 1.25), opacity 0.15s ease-out;
	z-index: 99
}

.nice-select .list:hover .option:not(:hover) {
	background-color: transparent !important
}

.nice-select .option {
	cursor: pointer;
	font-weight: 400;
	line-height: 32px;
	list-style: none;
	min-height: 32px;
	outline: none;
	padding-left: 15px;
	padding-right: 26px;
	text-align: left;
	transition: all 0.2s
}

.nice-select .option:hover,
.nice-select .option.focus,
.nice-select .option.selected.focus {
	background-color: #0044c2;
	background-color: rgba(0, 77, 218, 0.04)
}

.nice-select .option.selected {
	font-weight: 500
}

.nice-select .option.disabled {
	background-color: transparent;
	color: #757575;
	cursor: default
}

.no-csspointerevents .nice-select .list {
	display: none
}

.no-csspointerevents .nice-select.open .list {
	display: block
}

.add_bottom_10 {
	margin-bottom: 10px
}

.add_bottom_15 {
	margin-bottom: 15px
}

.add_bottom_20 {
	margin-bottom: 20px
}

.add_bottom_25 {
	margin-bottom: 25px
}

.add_bottom_30 {
	margin-bottom: 30px
}

.add_bottom_45 {
	margin-bottom: 45px
}

.add_bottom_60 {
	margin-bottom: 60px
}

@media (max-width: 767px) {
	.add_bottom_60 {
		margin-bottom: 30px
	}
}

.add_top_5 {
	margin-top: 5px
}

.add_top_10 {
	margin-top: 10px
}

.add_top_15 {
	margin-top: 15px
}

.add_top_20 {
	margin-top: 20px
}

.add_top_30 {
	margin-top: 30px
}

.add_top_60 {
	margin-top: 60px
}

.padding_bottom_35 {
	padding-bottom: 35px
}

.margin_30 {
	padding-top: 30px;
	padding-bottom: 30px
}

.margin_30_5 {
	padding-top: 30px;
	padding-bottom: 5px
}

.margin_60 {
	padding-top: 60px;
	padding-bottom: 60px
}

@media (max-width: 575px) {
	.margin_60 {
		padding-top: 30px;
		padding-bottom: 30px
	}
}

.margin_60_35 {
	padding-top: 60px;
	padding-bottom: 35px
}

@media (max-width: 575px) {
	.margin_60_35 {
		padding-top: 30px;
		padding-bottom: 5px
	}
}

.margin_90_65 {
	padding-top: 90px;
	padding-bottom: 65px
}

.margin_90_0 {
	padding-top: 90px;
	padding-bottom: 0
}

#sign-in-dialog {
	background: #fff;
	padding: 30px;
	padding-top: 0;
	text-align: left;
	max-width: 400px;
	margin: 40px auto;
	position: relative;
	box-sizing: border-box;
	border-radius: 4px
}

#sign-in-dialog .mfp-close {
	color: #666;
	background-color: #e4e4e4;
	border-radius: 50%;
	top: 12px;
	right: 20px;
	width: 32px;
	height: 32px;
	line-height: 32px
}

#sign-in-dialog .mfp-close:hover {
	color: #fff;
	background-color: #66676b
}

#sign-in-dialog .mfp-close:before {
	font-size: 16px;
	font-family: 'themify';
	content: "\e646"
}

.modal_header {
	font-size: 18px;
	width: calc(100% + 60px);
	position: relative;
	left: -30px;
	top: 0;
	border-radius: 4px 4px;
	display: inline-block;
	background-color: #f6f6f6;
	padding: 18px 20px 15px 20px;
	margin-bottom: 30px
}

.modal_header h3 {
	padding: 0;
	margin: 0;
	font-size: 18px;
	font-size: 1.125rem;
	font-weight: 500
}

#sign-in-dialog .form-group {
	position: relative
}

#sign-in-dialog .form-group input.form-control {
	padding-left: 40px
}

#sign-in-dialog .form-group>i {
	font-size: 21px;
	font-size: 1.3125rem;
	position: absolute;
	left: 10px;
	top: 32px;
	color: #ccc;
	width: 25px;
	height: 25px;
	display: block;
	font-weight: 400 !important
}

.my-toggle {
	background: transparent;
	border: 0;
	cursor: pointer;
	margin-right: 5px;
	height: 30px;
	line-height: 30px;
	padding: 0 10px;
	-moz-appearance: none;
	-webkit-appearance: none;
	outline: none !important;
	box-shadow: none;
	background-color: #fff
}

.my-toggle i {
	font-size: 14px;
	font-size: 0.875rem;
	position: static !important;
	color: #777
}

.my-toggle i:hover,
.my-toggle i:focus {
	color: #555
}

.hideShowPassword-wrapper {
	width: 100% !important
}

.checkboxes label {
	color: #999
}

a#forgot {
	color: #999;
	font-weight: 500
}

#forgot_pw {
	background-color: #fff;
	position: absolute;
	left: 0;
	top: 0;
	width: 100%;
	z-index: 99;
	min-height: 430px;
	display: none
}

#forgot_pw label {
	font-weight: 500
}

.sign-in-wrapper {
	position: relative;
	height: 100%
}

a.social_bt {
	border-radius: 3px;
	text-align: center;
	color: #fff;
	min-width: 200px;
	margin-bottom: 15px;
	display: block;
	padding: 12px;
	line-height: 1;
	position: relative;
	transition: all 0.3s ease-in-out;
	cursor: pointer
}

a.social_bt:hover {
	-webkit-filter: brightness(115%);
	filter: brightness(115%)
}

a.social_bt.facebook:before,
a.social_bt.google:before,
a.social_bt.linkedin:before {
	font-family: 'themify';
	position: absolute;
	left: 12px;
	top: 10px;
	font-size: 1rem;
	color: #fff
}

a.social_bt.facebook {
	background-color: #3B5998
}

a.social_bt.facebook:before {
	content: "\e741"
}

a.social_bt.google {
	background-color: #DC4E41
}

a.social_bt.google:before {
	content: "\e73e";
	top: 12px
}

.divider {
	text-align: center;
	height: 1px;
	margin: 20px 0 20px 0;
	background-color: #ededed
}

.divider span {
	position: relative;
	top: -20px;
	background-color: #fff;
	display: inline-block;
	padding: 10px;
	font-style: italic
}

.popup_wrapper {
	background: rgba(0, 0, 0, 0.7) none repeat scroll 0 0;
	height: 100%;
	opacity: 0;
	position: fixed;
	top: 0;
	left: 0;
	transition: all 0.5s ease;
	visibility: hidden;
	width: 100%;
	z-index: 9999999
}

.popup_wrapper .popup_content {
	background-color: #fff;
	top: 50%;
	left: 50%;
	right: 0;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	position: absolute;
	width: 500px
}

@media (max-width: 767px) {
	.popup_wrapper .popup_content {
		width: 300px
	}
}

.popup_wrapper .popup_content.newsletter {
	width: 800px
}

@media (max-width: 767px) {
	.popup_wrapper .popup_content.newsletter {
		width: 300px
	}
}

.popup_wrapper .popup_content.newsletter figure {
	position: absolute;
	overflow: hidden;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	margin: 0
}

.popup_wrapper .popup_content.newsletter figure img {
	height: 100%;
	width: auto
}

.popup_wrapper .popup_content.newsletter .content {
	height: 100%;
	padding: 120px 60px;
	text-align: center;
	display: -webkit-flex;
	display: flex;
	-webkit-align-items: center;
	        align-items: center
}

@media (max-width: 767px) {
	.popup_wrapper .popup_content.newsletter .content {
		padding: 30px 30px 15px 30px;
		height: auto
	}
}

.popup_wrapper .popup_content.newsletter .content .wrapper {
	width: 100%
}

.popup_wrapper .popup_content.newsletter .content .wrapper h3 {
	font-size: 21px;
	font-size: 1.3125rem;
	margin-top: 25px
}

.popup_wrapper .popup_close {
	color: #ffffff;
	cursor: pointer;
	display: block;
	text-align: center;
	text-decoration: none;
	background: #000;
	width: 53px;
	height: 25px;
	line-height: 27px;
	position: absolute;
	top: -25px;
	right: 0;
	font-size: 13px;
	font-weight: 700;
	transition: all 0.3s ease
}

.popup_wrapper .popup_close:hover {
	background: #cc0000
}

.clear:after,
.pagination:after,
.clear:before,
.pagination:before {
	content: "";
	display: table
}

.clear:after,
.pagination:after {
	clear: both
}

.pagination__wrapper {
	height: 50px;
	padding-top: 20px;
	margin-bottom: 25px;
	position: relative;
	text-align: center;
	width: 100%;
	border-top: 1px solid #ededed
}

.pagination__wrapper.no_border {
	border-top: none
}

.pagination__wrapper:before {
	top: -1px
}

.pagination__wrapper:after {
	bottom: -1px
}

@-webkit-keyframes hoverAnimation {
	from {
		opacity: 1
	}
	to {
		opacity: 0
	}
}

@keyframes hoverAnimation {
	from {
		opacity: 1
	}
	to {
		opacity: 0
	}
}

.pagination {
	display: inline-block;
	list-style: none;
	margin: 0;
	padding: 0
}

.pagination li {
	display: block;
	float: left;
	padding: 5px
}

.pagination li:first-child {
	border: none
}

.pagination a,
.pagination span {
	background: none;
	border: none;
	border-radius: 50%;
	box-sizing: border-box;
	color: rgba(0, 0, 0, 0.6);
	display: block;
	font-size: 16px;
	height: 40px;
	line-height: 40px;
	min-width: 40px;
	padding: 0
}

.pagination a {
	outline: none;
	position: relative;
	transition: all 170ms linear
}

.pagination a:before {
	background: rgba(0, 0, 0, 0.2);
	border-radius: 50%;
	content: "";
	cursor: pointer;
	height: 0;
	left: 50%;
	opacity: 0;
	position: absolute;
	-webkit-transform: translate(-50%, -50%);
	        transform: translate(-50%, -50%);
	transition: all 170ms linear;
	top: 50%;
	width: 0
}

.pagination a:hover:not(.active) {
	color: #000
}

.pagination a:hover:not(.active):before {
	-webkit-animation: hoverAnimation 510ms linear forwards;
	        animation: hoverAnimation 510ms linear forwards;
	height: 40px;
	width: 40px
}

.pagination a.active {
	background: rgba(0, 0, 0, 0.1);
	color: #000;
	cursor: default
}

.pagination .prev,
.pagination .next {
	font-size: 14px
}

@media (min-width: 768px) {
	.collapse.dont-collapse-sm {
		display: block !important;
		height: auto !important;
		visibility: visible !important
	}
}

footer {
	background-color: #172134;
	padding: 60px 0 35px 0
}

@media (max-width: 767px) {
	footer {
		padding: 0 0 15px 0
	}
}

footer hr {
	border-color: rgba(255, 255, 255, 0.15);
	margin-top: 5px
}

@media (max-width: 767px) {
	footer hr {
		border-color: transparent
	}
}

footer .contacts ul {
	margin: 0 0 25px 0;
	padding: 0;
	list-style: none
}

@media (max-width: 767px) {
	footer .contacts ul {
		margin-top: 15px
	}
}

footer .contacts ul li {
	padding-left: 35px;
	position: relative;
	margin-bottom: 15px;
	color: #fff;
	opacity: 0.8
}

footer .contacts ul li a {
	color: #fff;
	opacity: 1
}

footer .contacts ul li a:hover {
	opacity: 1
}

footer .contacts ul li i {
	font-size: 18px;
	font-size: 1.125rem;
	position: absolute;
	top: 0;
	left: 0;
	color: #fff;
	line-height: 1
}

footer h3 {
	font-size: 14px;
	font-size: 0.875rem;
	margin-bottom: 15px;
	transition: all 0.3s ease-in-out;
	color: #fff;
	text-transform: uppercase
}

@media (max-width: 767px) {
	footer h3 {
		font-size: 13px;
		font-size: 0.8125rem;
		cursor: pointer;
		border-bottom: 1px solid rgba(255, 255, 255, 0.15);
		margin: 0 -15px 0 -15px;
		padding: 15px 15px 15px 15px;
		display: block
	}
	footer h3:after {
		font-family: 'themify';
		content: "\e61a";
		width: 20px;
		display: block;
		float: right
	}
	footer h3.opened:after {
		content: "\e622"
	}
}

footer .links ul {
	margin: 0 0 25px 0;
	padding: 0;
	list-style: none
}

@media (max-width: 767px) {
	footer .links ul {
		margin-top: 15px
	}
}

footer .links ul li {
	margin-bottom: 8px;
	line-height: 1.2
}

footer .links ul li a {
	position: relative;
	color: #fff;
	opacity: 0.8;
	display: inline-block
}

footer .links ul li a:hover {
	-webkit-transform: translate(5px, 0);
	transform: translate(5px, 0);
	color: #fff;
	opacity: 1
}

footer .links ul li a:hover:after {
	opacity: 1;
	color: #fff
}

footer .links ul li a:after {
	font-family: 'themify';
	content: "\e628";
	position: relative;
	margin-left: 5px;
	top: 2px;
	opacity: 0;
	transition: all 0.5s ease
}

@-webkit-keyframes rotation {
	from {
		-webkit-transform: rotate(0deg);
		transform: rotate(0deg)
	}
	to {
		-webkit-transform: rotate(359deg);
		transform: rotate(359deg)
	}
}

@media (max-width: 767px) {
	#newsletter {
		margin-top: 20px
	}
}

#newsletter .loader {
	position: absolute;
	right: -22px;
	top: 10px;
	color: #ccc;
	font-size: 14px;
	font-size: 0.875rem;
	-webkit-animation: rotation 2s infinite linear;
	animation: rotation 2s infinite linear
}

#newsletter h6 {
	margin: 15px 0 15px 0
}

#newsletter input[type='email'] {
	border: 0;
	height: 40px;
	border-radius: 0;
	padding-left: 15px;
	background-color: #f3f3f3;
	background-color: rgba(255, 255, 255, 0.05);
	color: #ccc
}

#newsletter input[type='email']:focus {
	border: 0;
	box-shadow: none
}

#newsletter button[type='submit'] {
	position: absolute;
	right: 0;
	color: #fff;
	font-size: 18px;
	font-size: 1.125rem;
	top: 0;
	border: 0;
	padding: 0 12px;
	height: 40px;
	line-height: 44px;
	cursor: pointer;
	border-radius: 0 3px 3px 0;
	background-color: #004dda;
	transition: all 0.3s ease-in-out;
	outline: none
}

#newsletter button[type='submit']:hover {
	background-color: #FFC107;
	color: #222
}

.follow_us h5 {
	font-size: 16px;
	font-size: 1rem;
	margin: 35px 0 10px 0;
	color: #fff
}

.follow_us ul {
	margin: 0 0 25px 0;
	padding: 0;
	list-style: none
}

.follow_us ul li {
	display: inline-block;
	margin: 0 10px 0 0
}

.follow_us ul li a {
	display: block
}

.follow_us ul li a img {
	width: 30px;
	height: 30px
}

.follow_us ul li a:hover {
	-webkit-transform: translateY(-5px);
	transform: translateY(-5px)
}

ul.footer-selector {
	margin: 0;
	padding: 0;
	list-style: none
}

ul.footer-selector li {
	float: left;
	margin-right: 10px
}

@media (max-width: 575px) {
	ul.footer-selector li:last-child {
		padding-top: 10px;
		clear: both;
		float: none;
		display: block !important
	}
}

ul.additional_links {
	margin: 0;
	padding: 8px 0 0 0;
	color: #555;
	float: right
}

@media (max-width: 991px) {
	ul.additional_links {
		float: none;
		margin-top: 10px
	}
}

ul.additional_links li {
	display: inline-block;
	margin-right: 15px
}

ul.additional_links li:first-child {
	margin-right: 20px
}

ul.additional_links li:last-child:after {
	content: ""
}

ul.additional_links li span {
	color: #fff;
	opacity: 0.8
}

ul.additional_links li a {
	color: #fff;
	opacity: 0.8;
	transition: all 0.3s ease-in-out
}

ul.additional_links li a:hover {
	opacity: 1
}

ul.additional_links li:after {
	content: "|";
	font-weight: 300;
	position: relative;
	left: 10px
}

#cookie-bar {
	background: rgba(0, 0, 0, 0.8);
	height: auto;
	line-height: 24px;
	color: #eeeeee;
	text-align: center;
	padding: 15px 0;
	font-weight: 400;
	font-size: 13px;
	font-size: 0.8125rem;
	font-weight: 500
}

#cookie-bar p {
	margin: 0;
	padding: 0
}

#cookie-bar a {
	color: #fff;
	display: inline-block;
	border-radius: 3px;
	text-decoration: none;
	padding: 2px 10px;
	margin-left: 8px
}

@media (max-width: 767px) {
	#cookie-bar a {
		display: block;
		max-width: 150px;
		margin: auto;
		margin-bottom: 5px
	}
}

#cookie-bar .cb-enable {
	background: #32a067
}

#cookie-bar .cb-enable:hover {
	background: #fff;
	color: #083487
}

#cookie-bar .cb-disable {
	background: #990000
}

#cookie-bar .cb-disable:hover {
	background: #bb0000
}

#cookie-bar .cb-policy {
	background: #004dda
}

#cookie-bar .cb-policy:hover {
	background: #fff;
	color: #083487
}

#cookie-bar.fixed {
	position: fixed;
	top: 0;
	left: 0;
	width: 100%;
	z-index: 999999
}

#cookie-bar.fixed.bottom {
	bottom: 0;
	top: auto
}

@media (max-width: 767px) {
	#cookie-bar {
		padding: 5px 0
	}
}

.mfp-bg {
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	overflow: hidden;
	position: fixed;
	background: #000;
	opacity: 0.96
}

.mfp-wrap {
	top: -10px;
	left: 0;
	width: 100%;
	height: 100%;
	z-index: 999999;
	position: fixed;
	outline: none !important;
	-webkit-backface-visibility: hidden
}

.mfp-container {
	height: 100%;
	text-align: center;
	position: absolute;
	width: 100%;
	height: 100%;
	left: 0;
	top: 0;
	padding: 0 18px;
	box-sizing: border-box;
	overflow-x: hidden;
	overflow-y: auto
}

.mfp-container:before {
	content: '';
	display: inline-block;
	height: 100%;
	vertical-align: middle
}

.mfp-align-top .mfp-container:before {
	display: none
}

.mfp-content {
	position: relative;
	display: inline-block;
	vertical-align: middle;
	margin: 0 auto;
	text-align: left;
	z-index: 505
}

.mfp-inline-holder .mfp-content,
.mfp-ajax-holder .mfp-content {
	width: 100%;
	cursor: auto
}

.mfp-ajax-cur {
	cursor: progress
}

.mfp-zoom-out-cur,
.mfp-zoom-out-cur .mfp-image-holder .mfp-close {
	cursor: -webkit-zoom-out;
	cursor: zoom-out
}

.mfp-zoom {
	cursor: pointer;
	cursor: -webkit-zoom-in;
	cursor: zoom-in
}

.mfp-auto-cursor .mfp-content {
	cursor: auto
}

.mfp-close,
.mfp-arrow,
.mfp-preloader,
.mfp-counter {
	-webkit-user-select: none;
	-ms-user-select: none;
	    user-select: none
}

.mfp-loading.mfp-figure {
	display: none
}

.mfp-hide {
	display: none !important
}

.mfp-preloader {
	color: #aaa;
	position: absolute;
	top: 50%;
	width: auto;
	text-align: center;
	margin-top: -0.8em;
	left: 8px;
	right: 8px;
	z-index: 504
}

.mfp-preloader a {
	color: #cccccc
}

.mfp-preloader a:hover {
	color: #fff
}

.mfp-s-ready .mfp-preloader {
	display: none
}

.mfp-s-error .mfp-content {
	display: none
}

.mfp-s-loading .mfp-arrow {
	display: none
}

button.mfp-close,
button.mfp-arrow {
	overflow: visible;
	cursor: pointer;
	border: 0;
	-webkit-appearance: none;
	display: block;
	padding: 0;
	z-index: 506
}

.mfp-image-holder .mfp-close,
.mfp-iframe-holder .mfp-close {
	right: 0px;
	top: -40px
}

button.mfp-close {
	position: absolute;
	width: 45px;
	height: 45px;
	top: -20px;
	display: block;
	right: -12px;
	cursor: pointer !important;
	z-index: 9999;
	color: #fff;
	transition: all 0.2s ease-in-out;
	border-radius: 50%;
	margin: 0px;
	background-color: transparent;
	outline: none;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0)
}

.mfp-iframe-holder .mfp-close {
	top: -50px;
	right: -12px;
	-webkit-transform: translate3d(0, 0, 0);
	        transform: translate3d(0, 0, 0)
}

.mfp-gallery .mfp-close {
	top: 20px;
	right: 10px
}

.mfp-gallery .mfp-content .mfp-close {
	display: none
}

.mfp-gallery .mfp-close:before {
	font-family: 'themify';
	font-size: 29px;
	top: 4px;
	left: 50%;
	position: absolute;
	height: 10px;
	transition: 0.3s
}

.mfp-counter {
	position: absolute;
	top: 0;
	right: 0;
	color: #aaa;
	font-size: 13px;
	line-height: 18px
}

.mfp-arrow {
	opacity: 1;
	margin: 0 20px;
	top: 50%;
	-webkit-transform: translateY(-50%);
	        transform: translateY(-50%);
	z-index: 9999;
	color: #fff;
	transition: all 0.2s ease-in-out;
	cursor: pointer;
	width: 60px;
	height: 60px;
	position: absolute;
	display: block;
	z-index: 100;
	overflow: hidden;
	background: rgba(255, 255, 255, 0.15);
	border-radius: 50%;
	outline: none !important
}

.mfp-arrow:hover {
	background: #66676b;
	color: #fff
}

.mfp-arrow.mfp-arrow-left,
.mfp-arrow.mfp-arrow-right {
	font-family: 'themify';
	font-size: 32px
}

.mfp-arrow.mfp-arrow-right {
	right: 15px
}

.mfp-arrow.mfp-arrow-left:before {
	content: "\e64a";
	left: -1px;
	top: 0px;
	position: relative
}

.mfp-arrow.mfp-arrow-right:before {
	content: "\e649";
	left: 1px;
	top: 0px;
	position: relative
}

.mfp-content:hover .mfp-arrow {
	opacity: 1
}

.mfp-iframe-holder {
	padding-top: 40px;
	padding-bottom: 40px
}

.mfp-iframe-holder .mfp-content {
	line-height: 0;
	width: 100%;
	max-width: 1180px
}

.mfp-iframe-scaler {
	width: 100%;
	height: 0;
	overflow: hidden;
	padding-top: 54%
}

.mfp-iframe-scaler iframe {
	position: absolute;
	display: block;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
	box-shadow: 0 0 8px rgba(0, 0, 0, 0.6);
	background: black
}

.my-mfp-zoom-in .zoom-anim-dialog {
	opacity: 0;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	transform: scale(0.8)
}

.my-mfp-zoom-in.mfp-ready .zoom-anim-dialog {
	opacity: 1;
	-webkit-transform: scale(1);
	transform: scale(1)
}

.my-mfp-zoom-in.mfp-removing .zoom-anim-dialog {
	-webkit-transform: scale(0.8);
	transform: scale(0.8);
	opacity: 0
}

.my-mfp-zoom-in.mfp-bg {
	opacity: 0.001;
	transition: opacity 0.3s ease-out
}

.my-mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.8
}

.my-mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0
}

img.mfp-img {
	width: auto;
	max-width: 100%;
	height: auto;
	display: block;
	line-height: 0;
	box-sizing: border-box;
	margin: 40px auto;
	border-radius: 4px
}

.mfp-figure:after {
	content: '';
	position: absolute;
	left: 0;
	top: 40px;
	bottom: 40px;
	display: block;
	right: 0;
	width: auto;
	height: auto;
	z-index: -1;
	box-shadow: 0 0 18px rgba(11, 11, 11, 0.6)
}

.mfp-figure {
	line-height: 0
}

.mfp-bottom-bar {
	position: absolute;
	top: 100%;
	left: 0;
	width: 100%;
	cursor: auto
}

.mfp-title {
	text-align: left;
	line-height: 18px;
	color: #f3f3f3;
	word-break: break-word;
	padding-right: 36px
}

.mfp-figure small {
	color: #bdbdbd;
	display: block;
	font-size: 12px;
	line-height: 14px
}

.mfp-image-holder .mfp-content {
	max-width: 100%
}

.mfp-gallery .mfp-image-holder .mfp-figure {
	cursor: pointer
}

.mfp-zoom-in .mfp-with-anim {
	opacity: 0;
	transition: all 0.2s ease-in-out;
	-webkit-transform: scale(0.8);
	        transform: scale(0.8)
}

.mfp-zoom-in.mfp-bg {
	opacity: 0;
	transition: all 0.3s ease-out
}

.mfp-zoom-in.mfp-ready .mfp-with-anim {
	opacity: 1;
	-webkit-transform: scale(1);
	        transform: scale(1)
}

.mfp-zoom-in.mfp-ready.mfp-bg {
	opacity: 0.9
}

.mfp-zoom-in.mfp-removing .mfp-with-anim {
	-webkit-transform: scale(0.8);
	        transform: scale(0.8);
	opacity: 0
}

.mfp-zoom-in.mfp-removing.mfp-bg {
	opacity: 0
}

@media screen and (max-width: 800px) and (orientation: landscape),
screen and (max-height: 300px) {
	.mfp-img-mobile .mfp-image-holder {
		padding-left: 0;
		padding-right: 0
	}
	.mfp-img-mobile img.mfp-img {
		padding: 0
	}
	.mfp-img-mobile .mfp-figure:after {
		top: 0;
		bottom: 0
	}
	.mfp-img-mobile .mfp-bottom-bar {
		background: rgba(0, 0, 0, 0.6);
		bottom: 0;
		margin: 0;
		top: auto;
		padding: 3px 5px;
		position: fixed;
		box-sizing: border-box
	}
	.mfp-img-mobile .mfp-bottom-bar:empty {
		padding: 0
	}
	.mfp-img-mobile .mfp-counter {
		right: 5px;
		top: 3px
	}
	.mfp-img-mobile .mfp-close {
		top: 0;
		right: 0;
		width: 35px;
		height: 35px;
		line-height: 35px;
		background: rgba(0, 0, 0, 0.6);
		position: fixed;
		text-align: center;
		padding: 0
	}
	.mfp-img-mobile .mfp-figure small {
		display: inline;
		margin-left: 5px
	}
}

.mfp-fade.mfp-bg {
	opacity: 0;
	transition: all 0.2s ease-out
}

.mfp-fade.mfp-bg.mfp-ready {
	opacity: 0.92;
	background-color: #111
}

.mfp-fade.mfp-bg.mfp-removing {
	opacity: 0
}

.mfp-fade.mfp-wrap .mfp-content {
	opacity: 0;
	transition: all 0.2s ease-out
}

.mfp-fade.mfp-wrap.mfp-ready .mfp-content {
	opacity: 1
}

.mfp-fade.mfp-wrap.mfp-removing .mfp-content {
	opacity: 0
}

.mfp-bottom-bar {
	padding: 0 10px !important
}

.mfp-counter {
	right: 10px !important;
	color: #fff !important
}

.mfp-bg {
	opacity: 0.8 !important
}
