.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-spin infinite 20s linear;
  }
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.navWrapperStyle {
  align-items: center;
  display: flex;
  justify-content: center;
}

.navLinkStyle {
  display: flex;
  align-items: center;
  font-size: 0.9em;
  color: #FFF;
  margin-right: 15px;
  margin-left: 15px;
  font-weight: bold;
}

.navLinkStyle:hover{
  color: #00BCD4;
}

.secondaryNavLinkStyle {
  display: flex;
  align-items: center;
  font-size: 0.8em;
  color: #333;
  margin-right: 10px;
  margin-left: 10px;
  font-weight: bold;
}

.navBarIconStyle{
  margin-left:10px;
  margin-right:10px;
  font-size:1.5em;
}

.underCarouselSectionHeader {
  font-size:1.2em;
  font-weight: bold;
  color: #333;
}

.underCarouselSectionWrapper{
  border-left-width: 1px;
  border-left-color: #CCC;
  border-left-style: solid;
  padding-top:15px;
  padding-bottom:15px;
}

.underCarouselItemWrapper {
  display: flex;
  flex-direction: row;
  justify-content: center;
}

.underCarouselIcon {
  font-size:2.2em;
  margin-right:20px;
  color: #03A9F4 !important;
}


/* --------------------------------------- */

.footer {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
  /* padding: 50px; */
  margin-top: 50px;
}

.footer .footer-left,
.footer .footer-center,
.footer .footer-right {
  display: inline-block;
  vertical-align: top;
}


/* footer left*/

.footer .footer-left {
  width: 33%;
  padding-right: 15px;
}

.footer .about {
  line-height: 20px;
  color: #ffffff;
  font-size: 13px;
  font-weight: normal;
  margin: 0;
}

.footer .about span {
  display: block;
  color: #ffffff;
  font-size: 14px;
  font-weight: bold;
  margin-bottom: 20px;
}

.footer .icons {
  margin-top: 25px;
}

.footer .icons a {
  display: inline-block;
  width: 35px;
  height: 35px;
  cursor: pointer;
  background-color: #33383b;
  border-radius: 2px;
  font-size: 20px;
  color: #ffffff;
  text-align: center;
  line-height: 35px;
  margin-right: 3px;
  margin-bottom: 5px;
}


/* footer center*/

.footer .footer-center {
  width: 30%;
}

.footer .footer-center i {
  background-color: #33383b;
  color: #ffffff;
  font-size: 25px;
  width: 38px;
  height: 38px;
  border-radius: 50%;
  text-align: center;
  line-height: 42px;
  margin: 10px 15px;
  vertical-align: middle;
}

.footer .footer-center i.fa-envelope {
  font-size: 17px;
  line-height: 38px;
}

.footer .footer-center p {
  display: inline-block;
  color: #ffffff;
  vertical-align: middle;
  margin: 0;
}

.footer .footer-center p span {
  display: block;
  font-weight: normal;
  font-size: 14px;
  line-height: 2;
}

.footer .footer-center p a {
  color: #0099ff;
  text-decoration: none;
}


/* footer right*/

.footer .footer-right {
  width: 35%;
}

.footer h2 {
  color: #ffffff;
  font-size: 36px;
  font-weight: normal;
  margin: 0;
}

.footer h2 span {
  color: #0099ff;
}

.footer .menu {
  color: #ffffff;
  margin: 20px 0 12px;
  padding: 0;
}

.footer .menu a {
  display: inline-block;
  line-height: 1.8;
  text-decoration: none;
  color: inherit;
}

.footer .menu a:hover {
  color: #0099ff;
}

.footer .name {
  color: #0099ff;
  font-size: 14px;
  font-weight: normal;
  margin: 0;
}

@media (max-width: 767px) {
  .footer {
    font-size: 14px;
  }
  .footer .footer-left,
  .footer .footer-center,
  .footer .footer-right {
    display: block;
    width: 100%;
    margin-bottom: 40px;
    text-align: center;
  }
  .footer .footer-center i {
    margin-left: 0;
  }
}

.footerpaymenticon{
  width:40px;
  height:auto;
  margin-right:10px;
  margin-top:5px;
}

/* DEMO GENERAL ============================== */
.hover {
  /* overflow: hidden; */
  width:100%;
  /* height:100%; */
  position: relative;
  padding-bottom: 100%;
  /* padding:10px; */
  /* margin:10px; */
}

.hover-overlay {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  z-index: 90;
  transition: all 0.4s;
}

.hover img {
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  transition: all 0.3s;
}

.hover-content {
  position: relative;
  z-index: 99;
}


/* DEMO 1 ============================== */
.hover-1 img {
  /* width: 105%; */
  position: absolute;
  top: 0;
  left: -2%;
  transition: all 0.3s;
}

.hover-1-content {
  position: absolute;
  bottom: 0;
  left: 0;
  z-index: 99;
  transition: all 0.4s;
  padding-left:15px;
}

.hover-1 .hover-overlay {
  /* background: rgba(0, 0, 0, 0.1); */
}

.hover-1-description {
  transform: translateY(0.5rem);
  transition: all 0.4s;
  opacity: 0;
  color:#333;
}

.hover-1:hover .hover-1-content {
  bottom: 2rem;
}

.hover-1:hover .hover-1-description {
  opacity: 1;
  transform: none;
}

.hover-1:hover img {
  left: 0;
}

.hover-1:hover .hover-overlay {
  opacity: 0;
}


.relatedproductitem{ 
  width:200px;
  height:auto;
  margin: 0px 5px 0px 5px;
  /* background-color: #0099ff; */
  /* height:200px; */
  display: flex;
  flex-direction:column;
  justify-content: center;
  align-items: center;
}


.footerminimal {
  background-color: #414141;
  width: 100%;
  text-align: left;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 16px;
}